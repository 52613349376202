import { Flex, Spin } from 'antd';

import styles from './styles.module.scss';

export const Spinner = () => {
  return (
    <Flex align="center" justify="center" className={styles.container}>
      <Spin />
    </Flex>
  );
};
