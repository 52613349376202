import { type FC, type ReactNode, useMemo } from 'react';

import styles from './styles.module.scss';

export type LabelPropsType = 'active' | 'future' | 'ended';

export interface LabelProps {
  children: ReactNode
  type: LabelPropsType
}

export const Label: FC<LabelProps> = ({ children, type }) => {
  const labelStyle = useMemo(
    () => (type === 'active' ? styles.label : `${styles.label} ${styles.futureLabel}`),
    [type],
  );

  return <div className={labelStyle}>{children}</div>;
};
