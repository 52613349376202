import { ExclamationCircleOutlined } from '@ant-design/icons';
import { message, Modal } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auctionService } from '../../../../common/services/auctions';
import { lotService } from '../../../../common/services/lots';
import { ROUTES } from '../../../../routes/routeNames';
import { useAuctionsStore } from '../../store/useAuctionsStore';
import { useToken } from '../../../../common/hooks/useToken';
import { useValidateLots } from '../../../../common/hooks/useValidateLots';

export const useEditAuction = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [modal, contextHolder] = Modal.useModal();
  const { adminToken } = useToken()
  const navigate = useNavigate();
  const { validateLots } = useValidateLots()

  const {
    updateLots,
    createLots,
    auction,
    shouldUpdateGeneralInfo,
    updateAuctionInfo,
    updateLotsInfo,
    setLotsToCreate,
  } = useAuctionsStore((state) => state);

  const handleSubmit = useCallback(
    (withPublish?: boolean) => async () => {
      const validationErrors = await validateLots(createLots);
      if (validationErrors !== null) {
        message.error('Ошибка при обновлении Аукциона')
        return;
      }

      setIsLoading(true);
      let successMessageShown = false;

      try {
        if (updateLots) {
          await Promise.all(
            updateLots.map(
              async ({ id, name, artistFullName, size, techniqueAndMaterials, index }) =>
                await lotService
                  .editLot(id, { name, artistFullName, size, techniqueAndMaterials, index })
                  .catch((e) => message.error(e.message)),
            ),
          ).then(() => {
            successMessageShown = true;
          });
        }

        if (createLots) {
          await Promise.all(
            createLots.map(
              async ({ auctionId, artistFullName, size, techniqueAndMaterials, name, file, lotStartAmount, index }) =>
                await lotService
                  .createLot({
                    auctionId: auction?.id ? auction.id : auctionId,
                    artistFullName,
                    size,
                    techniqueAndMaterials,
                    name,
                    file,
                    lotStartAmount,
                    index
                  })
                  .catch((e) => message.error(e.message)),
            ),
          ).then(() => { setLotsToCreate([]); successMessageShown = true });
        }

        if (auction && shouldUpdateGeneralInfo) {
          await auctionService
            .updateAuctionById(
              { name: auction.name, file: auction.file, datetime: auction.datetime },
              auction?.id,
              adminToken
            ).then(() => {
              successMessageShown = true;
            })
            .catch((e) => message.error(e.message));
        }

        if (withPublish && auction) {
          await auctionService?.publishAuction(auction?.id, adminToken).then(() => {
            successMessageShown = true;
          }).catch((e) => message.error(e.message));
        }
      } finally {
        setIsLoading(false);
        if (successMessageShown) {
          message.success('Аукцион успешно обновлён')
        }
      }
    },
    [auction, createLots, updateLots, shouldUpdateGeneralInfo],
  );

  const handlePublish = useCallback(() => {
    modal.confirm({
      title: 'Опубликовать аукцион',
      icon: <ExclamationCircleOutlined />,
      content:
        'Вы действительно хотите опубликовать этот аукцион? Он сразу будет отображаться у пользователей',
      okText: 'Опубликовать',
      cancelText: 'Отменить',
      onOk: handleSubmit(true),
    });
  }, [handleSubmit, modal]);

  const handleDeleteAuction = useCallback(() => {
    if (auction) {
      modal.confirm({
        title: 'Удалить аукцион',
        icon: <ExclamationCircleOutlined />,
        content: 'Вы действительно хотите удалить этот аукцион?',
        okText: 'Удалить',
        cancelText: 'Отменить',
        onOk: async () =>
          await auctionService
            .deleteAuctionById(auction.id, adminToken)
            .then(() => { navigate(ROUTES.ADMIN.URL); })
            .catch((e) => message.error(e)),
      });
    }
  }, [auction, modal, navigate]);

  const handleGoBack = useCallback(() => {
    navigate(ROUTES.ADMIN.URL);
  }, [navigate]);

  useEffect(() => {
    return () => {
      updateAuctionInfo(null)
      updateLotsInfo([])
    }
  }, [updateAuctionInfo, updateLotsInfo]);

  return {
    isLoading,
    contextHolder,
    handleSubmit,
    handlePublish,
    handleDeleteAuction,
    handleGoBack,
  };
};
