import { Button, Flex, Space, Typography } from 'antd';
import { type FC } from 'react';

import { PageTitle } from '../../../common/components/PageTitle';

import { useCreateAuction } from './hooks/useCreateAuction';
import { CreateLot } from './steps/CreateLot';
import { GeneralInfo } from './steps/GeneralInfo';
import styles from './styles.module.scss';

export const CreateAuction: FC = () => {
  const {
    createLots,
    currentStep,
    displayStepTitle,
    handleNextStep,
    handleArrowPress,
    isLoading,
    handleSubmit,
    handleSubmitPublishing,
    contextHolder,
  } = useCreateAuction();

  return (
    <>
      {currentStep === 1
        ? (
        <Flex justify="space-between">
          <PageTitle withBackArrow title="Создать аукцион" onClickArrow={handleArrowPress} />
          <Space>
            <Button onClick={handleSubmitPublishing} disabled={!createLots?.[0]?.name || !createLots?.[0]?.artistFullName || !createLots?.[0]?.techniqueAndMaterials || !createLots?.[0]?.file}>Сохранить и опубликовать</Button>
            <Button loading={isLoading} onClick={handleSubmit} type="primary" disabled={!createLots?.[0]?.name || !createLots?.[0]?.size || !createLots?.[0]?.artistFullName || !createLots?.[0]?.techniqueAndMaterials || !createLots?.[0]?.file }>
              Сохранить
            </Button>
          </Space>
        </Flex>
          )
        : (
        <PageTitle withBackArrow title="Создать аукцион" onClickArrow={handleArrowPress} />
          )}
      <Typography className={styles.stepTitle}>{displayStepTitle()}</Typography>
      {currentStep === 0 ? <GeneralInfo onClick={handleNextStep} /> : <CreateLot />}
      {contextHolder}
    </>
  );
};
