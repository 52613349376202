import { ApiRoute } from 'src/common/constans/apiRoutes';
import { type UserLoginBody } from 'src/types';
import { callApi } from 'src/api/callApi';
import { parse } from 'path';

function parseJwt (token: string) {
  const base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

export const authService = {
  async loginUser () {
    try {
      const WebApp = (window as any).Telegram.WebApp;
      const USER_BODY: UserLoginBody = {
        telegramData: WebApp.initData,
      }
      const bodyData = JSON.stringify(USER_BODY)
      const res = await callApi<any>({
        route: ApiRoute.AUTH + '/user-login',
        method: 'POST',
        body: bodyData,
      });

      if (res) {
        const userId = parseJwt(JSON.parse(JSON.stringify(res)).access_token).sub
        
        localStorage.setItem('token', res.access_token)
        localStorage.setItem('userId', userId)
      }
      return true;
    } catch (error) {
      return false;
    }
  }
}
