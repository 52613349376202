import { type AxiosError } from 'axios';
import { callApi } from '../../../api/callApi';
import { ApiRoute } from '../../constans/apiRoutes';

import { type LoginBody } from './interfaces/model';
import { type UserResponse } from './interfaces/response';

export const generalService = {
  adminLogin: async (data: LoginBody): Promise<UserResponse> => {
    try {
      const bodyData = JSON.stringify(data);

      return await callApi<UserResponse>({
        route: ApiRoute.ADMIN_LOGIN,
        method: 'POST',
        body: bodyData,
      });
    } catch (error) {
      console.error(error);
      const AXIOS_ERROR = error as AxiosError;
      return { error: (AXIOS_ERROR?.response?.data as any).message, access_token: null, expire_in: null }
    }
  },

  refresh: async (token: string) => {
    try {
      const res = await callApi<UserResponse>({
        route: `${ApiRoute.AUTH}/refresh-tokens`,
        method: 'POST',
        excludeContentType: true,
        additionalHeaders: {
          Authorization: `Bearer ${token}`,
        },
      })
      if (res.access_token) { localStorage.setItem('token', res.access_token); }
      return res;
    } catch (error) {
      console.error('GeneralService: refresh error', error)
      return {
        access_token: null,
        expire_in: 0
      };
    }
  }
};
