import { type Tab } from 'rc-tabs/lib/interface';
import { AuctionsList } from './components/AuctionsList';
import { FutureAuctionsList } from './components/FutureAuctionsList';

export const TabsData: Tab[] = [
  {
    key: '0',
    label: 'Предстоящие',
    children: <FutureAuctionsList />,
  },
  {
    key: '1',
    label: 'Сегодня',
    children: <AuctionsList />,
  },
]
