import axios, { type AxiosError, isAxiosError } from 'axios';
import { type ApiRoute, REACT_APP_API_URL } from '../common/constans/apiRoutes';
import { authService } from 'src/common/services/auth';

export interface IAPIParams {
  baseUrl?: string
  route: ApiRoute | string
  method?: 'GET' | 'POST' | 'PUT' | 'HEAD' | 'PATCH' | 'DELETE'
  body?: any
  contentType?: string
  excludeContentType?: boolean
  additionalHeaders?: Record<string, string>
}

export const callApi = async <T>(params: IAPIParams): Promise<Awaited<T>> => {
  let TOKEN = localStorage.getItem('token');
  const CONFIG = {
    url: params.route,
    method: params.method ?? 'GET',
    baseURL: REACT_APP_API_URL + '/api',
    data: params.body ?? {},
    headers: {
      'Content-Type': 'application/json',
      Authorization: TOKEN ? `Bearer ${TOKEN}` : '',
      ...params.additionalHeaders,
    },
    withCredentials: true,
  };

  const executeRequest = async () => {
    try {
      const response = await axios(CONFIG);
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const AXIOS_ERROR = error as AxiosError;
        const MESSAGE = (AXIOS_ERROR.response?.data as any).message;

        if (MESSAGE === 'Отсутствует токен' || MESSAGE === 'Токен истек') {
          const result = await authService.loginUser();

          if (result) {
            TOKEN = localStorage.getItem('token');
            CONFIG.headers.Authorization = TOKEN ? `Bearer ${TOKEN}` : '';
            const retryResponse = await axios(CONFIG);
            return retryResponse.data;
          }
        }

        throw error as AxiosError;
      } else {
        throw new Error('Different error than axios');
      }
    }
  };

  return await executeRequest();
};
