import { Flex } from 'antd';
import { type ChangeEvent, type FC, useCallback } from 'react';
import { DefaultInput } from '../../../../common/components/DefaultInput';
import styles from './styles.module.scss';
import { type ILot } from 'src/common/types';

interface LotInputFieldsProps {
  lot: ILot
  handleInputChange?: (
    lotIndex: string,
    key: keyof ILot,
  ) => (event: ChangeEvent<HTMLInputElement>) => void
}

export const LotInputFields: FC<LotInputFieldsProps> = ({ lot, handleInputChange }) => {
  const handleChange = useCallback(
    (key: keyof ILot) => (event: ChangeEvent<HTMLInputElement>) => {
      handleInputChange?.(lot.id, key)(event);
    },
    [handleInputChange, lot.id],
  );

  return (
    <Flex vertical className={styles.input}>
        <DefaultInput
          onChange={handleChange('name')}
          defaultValue={lot?.name}
          name="name"
          className={styles.input}
          label="Название работы"
        />
        <DefaultInput
          onChange={handleChange('artistFullName')}
          defaultValue={lot?.artistFullName}
          className={styles.input}
          name="artistFullName"
          label="ФИО художника"
        />
        <DefaultInput
          onChange={handleChange('techniqueAndMaterials')}
          defaultValue={lot?.techniqueAndMaterials}
          name="techniqueAndMaterials"
          label="Техника и материалы"
        />
      <Flex className={styles.input} gap={10}>
        <DefaultInput
          onChange={handleChange('size')}
          defaultValue={lot?.size}
          name="size"
          className={styles.input}
          label="Размеры"
        />
        <DefaultInput
          className={styles.input}
          min={1000}
          max={100000}
          type='number'
          onChange={handleChange('lotStartAmount')}
          defaultValue={lot?.lotStartAmount}
          name="lotStartAmount"
          label="Начальная цена лота"
        />
      </Flex>
    </Flex>
  );
};
