import { Empty, Typography } from 'antd';

import styles from './styles.module.scss';

export const EmptyTab = ({ description }: { description: string }) => {
  return (
    <Empty
      className={styles.emptyContainer}
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={<Typography className={styles.title}>{description}</Typography>}
    />
  );
};
