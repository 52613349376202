import { Button, Flex, Space } from 'antd';

import { PageTitle } from '../../../common/components/PageTitle';
import { Spinner } from '../../../common/components/Spinner';
import { SidedLayout } from '../../../common/layouts/SidedLayot';

import { EditGeneralInfo } from './EditGeneralInfo';
import { EditLots } from './EditLots';
import { useEditAuction } from './hooks/useEditAuction';
import { useEditGeneralInfo } from './hooks/useEditGeneralInfo';
import { useEditLot } from './hooks/useEditLot';

export const EditAuction = () => {
  const {
    handleSubmit,
    isLoading: isSubmitting,
    handlePublish,
    contextHolder,
    handleDeleteAuction,
    handleGoBack,
  } = useEditAuction();

  const { isLoading: isAuctionLoading, auction } = useEditGeneralInfo();
  const { isLoading: isLotsLoading } = useEditLot();

  if (isAuctionLoading || isLotsLoading) {
    return <Spinner />;
  }

  return (
    <Flex vertical gap={26}>
      <Flex justify="space-between">
        <PageTitle onClickArrow={handleGoBack} withBackArrow title="Редактировать аукцион" />
        <Space>
          <Button onClick={handleDeleteAuction}>Удалить</Button>
            <Button onClick={handlePublish} disabled={!!auction?.publishedAt} htmlType="submit">
            Сохранить и опубликовать
          </Button>
          <Button loading={isSubmitting} onClick={handleSubmit()} type="primary">
            Сохранить
          </Button>
        </Space>
      </Flex>
      <SidedLayout leftChildren={<EditGeneralInfo />} rightChildren={<EditLots />} />
      {contextHolder}
    </Flex>
  );
};
