import React, {
  type ChangeEvent,
  type RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import { type ILot } from 'src/common/types';
import { useAuctionsStore } from '../../store/useAuctionsStore';
import { message } from 'antd';

export interface LotsToCreate extends ILot {
  file: File | string
  auctionId: string
}

export const useCreateLot = () => {
  const [lotsToCreate, setLotsToCreate] = useState<LotsToCreate[]>([]);
  const [imagePaths, setImagePaths] = useState<string[]>([]);

  const itemRefs = useRef<Array<React.RefObject<HTMLInputElement>>>([]);

  const {
    auction,
    setLotsToCreate: updateLotsToCreate,
    deleteLotToCreate,
  } = useAuctionsStore((store) => store);

  const handleImageChange = useCallback(
    (uploadRef: RefObject<HTMLInputElement>, index: number) => () => {
      const uploadFile = uploadRef.current?.files?.[0];

      if (!uploadFile) {
        void message.error('File not exist');
        return;
      }

      const cashedUrl = URL.createObjectURL(uploadFile);

      setImagePaths((prevImagePaths) => {
        const newImagePaths = [...prevImagePaths];
        newImagePaths[index] = cashedUrl;
        return newImagePaths;
      });

      if (uploadFile) {
        lotsToCreate[index].file = uploadFile;
      }
    },
    [lotsToCreate],
  );

  const handleInput = useCallback(
    (lotId: string, key: keyof ILot) => (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;

      setLotsToCreate((prevLots) => {
        return prevLots.map((lot) => {
          if (lot.id === lotId) {
            return {
              ...lot,
              [key]: value,
            };
          }
          return lot;
        });
      });
    },
    [],
  );

  const addNewLot = useCallback(() => {
    setLotsToCreate((prevLots) => [
      ...prevLots,
      {
        file: '',
        auctionId: '',
        id: uuidv4(),
        name: '',
        size: '',
        artistFullName: '',
        techniqueAndMaterials: '',
        pathToCover: '',
        ttlMilliseconds: 0,
        lotStartAmount: '1000',
        index: prevLots.length,
      },
    ]);
  }, [auction?.id]);

  useEffect(() => {
    updateLotsToCreate(lotsToCreate);
  }, [lotsToCreate, updateLotsToCreate]);

  useEffect(() => {
    if (lotsToCreate && lotsToCreate.length > 0) {
      itemRefs.current = lotsToCreate.map(() => React.createRef<HTMLInputElement>());
    }
  }, [lotsToCreate]);

  const handleDeleteLot = useCallback(
    (id: string) => () => {
      setLotsToCreate((prevLots) => prevLots.filter((lot) => lot.id !== id));
      deleteLotToCreate(id);
    },
    [deleteLotToCreate],
  );

  return {
    addNewLot,
    lotsToCreate,
    handleInput,
    handleImageChange,
    itemRefs,
    imagePaths,
    handleDeleteLot,
  };
};
