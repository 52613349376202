import { Flex } from 'antd';
import { type FC } from 'react';
import { AuctionCard } from '../../../../common/components/AuctionCard';
import { EmptyTab } from '../../../../common/components/EmptyTab';
import { Spinner } from '../../../../common/components/Spinner';
import { useDateTime } from '../../../../common/hooks/useDateTime';
import { useAuctionsStore } from '../../store/useAuctionsStore';
import { TabPublishingParams, useAuctionsList } from '../hooks/useAuctionsList';

export interface AuctionsListProps {
  publishingParam: TabPublishingParams
}

export const AuctionsList: FC<AuctionsListProps> = ({ publishingParam }) => {
  const auctions = useAuctionsStore((state) => state.auctions);
  const searchQuery = useAuctionsStore((state) => state.searchQuery);

  const [, calculateLabel] = useDateTime();

  const { isLoading, emptyDescription, renderCardAction, contextHolder, handleCardPress } =
    useAuctionsList(publishingParam);

  const filteredAuctions = auctions.filter((auction) =>
    auction.name.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      {auctions.length > 0 && filteredAuctions.length > 0
        ? (
        <Flex gap={16} wrap="wrap">
          {filteredAuctions.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()).map(({ datetime, name, id, pathToCover }) => {
            const label = calculateLabel(datetime);

            return (
              <AuctionCard
                onClick={handleCardPress(id)}
                key={id}
                datetime={datetime}
                gallery={name}
                imageSrc={pathToCover}
                type={publishingParam === TabPublishingParams.Published ? label : undefined}
                cardAction={renderCardAction(id)}
              />
            );
          })}
        </Flex>
          )
        : (
        <EmptyTab description={emptyDescription} />
          )}
      {contextHolder}
    </>
  );
};
