import { LoadingOutlined } from '@ant-design/icons';
import { message } from 'antd';
import { type FC, type PropsWithChildren, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Spinner } from 'src/common/components/Spinner';
import { authService } from 'src/common/services/auth';

const useTelegramWebApp = () => {
  const [webApp, setWebApp] = useState(null);

  useEffect(() => {
    const checkTelegramWebApp = () => {
      if (window?.Telegram?.WebApp) {
        setWebApp(window.Telegram.WebApp);
      } else {
        message.warning('Telegram WebApp not available yet. Retrying...');
        setTimeout(checkTelegramWebApp, 500); // Retry after 500ms
      }
    };
    checkTelegramWebApp();
  }, []);

  return webApp;
};

export const MainLayout: FC<PropsWithChildren> = () => {
  const webApp = useTelegramWebApp();
  const [isAuth, setIsAuth] = useState(false);

  const loginUserTG = async () => {
    if (!webApp) {
      message.error('loginUserTG: Telegram WebApp is not available.');
      return;
    }

    try {
      const res = await authService.loginUser();
      if (res) setIsAuth(true);
    } catch (error) {
      message.error('loginUserTG: Auth Error');
      console.error('loginUserTG: Auth Error', error);
      return;
    }

    if (window.location.pathname === '/login') return;

    try {
      (webApp as any).expand();
    } catch (error) {
      console.error('loginUserTG: Error interacting with WebApp', error);
    }
  };

  useEffect(() => {
    if (webApp) {
      loginUserTG();
    }
  }, [webApp]);

  return (
    <>
      {isAuth ? <Outlet /> : <Spinner />}
    </>
  );
};
