import { Avatar, Flex, Layout, Space, Typography } from 'antd';
import { type FC, useCallback } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import DEFAULT_AVATAR from '../../../assets/icons/mocked-avatar.png';
import { ROUTES } from '../../../routes/routeNames';
import { Logo } from '../../components/Logo';
import { MenuButton } from '../../components/MenuButton';

import styles from './styles.module.scss';

const { Header, Content } = Layout;

const data = [
  { label: 'Аукционы', href: ROUTES.ADMIN.URL },
  { label: 'Сообщения', href: ROUTES.MESSAGES.URL },
  { label: 'Участники', href: ROUTES.PARTICIPANTS.URL },
];

export const AdminLayout: FC = () => {
  const location = useLocation();

  const handleActiveLink = useCallback(
    (link: string, id: number) => {
      if (location.pathname === link) {
        return true;
      }

      if (
        location.pathname !== ROUTES.MESSAGES.URL &&
        location.pathname !== ROUTES.PARTICIPANTS.URL
      ) {
        return id === 0;
      }

      return false;
    },
    [location],
  );

  return (
    <Layout className={styles.layout}>
      <Header className={styles.header}>
        <Logo />
        <Flex align="center" gap={10}>
          {data.map(({ label, href }, id) => (
            <MenuButton key={label} isActive={handleActiveLink(href, id)} href={href}>
              {label}
            </MenuButton>
          ))}
        </Flex>
        <Space direction="horizontal" size={12}>
          <Avatar src={DEFAULT_AVATAR} size={24} />
          <Typography>Admin</Typography>
        </Space>
      </Header>
      <Content className={styles.content}>
        <Outlet />
      </Content>
    </Layout>
  );
};
