import { type IBet, type ILot } from 'src/common/types';
import { callApi } from '../../../api/callApi';
import { ApiRoute } from '../../constans/apiRoutes';
import { type CreateLotBody } from './interfaces/model';
import {
  type CreateLotResponse,
  type GetLotsByIdResponse,
} from './interfaces/response';
import { type IMyLot } from 'src/pages/MyLots';
import { type IMyBet } from 'src/pages/MyBets';
import { type IFutureLot } from 'src/pages/Auction';

export const lotService = {
  createLot: async (data: CreateLotBody) => {
    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('artistFullName', data.artistFullName);
    formData.append('auctionId', data.auctionId);
    formData.append('size', data.size);
    formData.append('index', data.index.toString());
    formData.append('lotStartAmount', data.lotStartAmount);
    formData.append('techniqueAndMaterials', data.techniqueAndMaterials);
    formData.append('file', data.file);

    return await callApi<CreateLotResponse>({
      route: ApiRoute.LOTS,
      method: 'POST',
      body: formData,
      additionalHeaders: {
        'Content-Type': 'multipart/form-data'
      },
    });
  },

  getAdminLotsByAuctionId: async (auctionId: string) => {
    try {
      return await callApi<ILot[]>({
        route: `${ApiRoute.LOTS}/auction/${auctionId}`,
      });
    } catch (error) {
      console.error('Error in getAdminLotsByAuctionId', error)
      return [];
    }
  },

  getUserLotsByAuctionId: async (auctionId: string) => {
    try {
      return await callApi<IFutureLot[]>({
        route: `${ApiRoute.USER_LOTS}/auction/${auctionId}`,
      });
    } catch (error) {
      console.error('Error in getUserLotsByAuctionId', error)
      return [];
    }
  },

  deleteLot: async (lotId: string) => {
    return await callApi<GetLotsByIdResponse>({
      method: 'DELETE',
      route: `${ApiRoute.LOTS}/${lotId}`,
    });
  },

  editImage: async (lotId: string, file: File) => {
    const formData = new FormData();
    formData.append('file', file);

    return await callApi<ILot>({
      method: 'PATCH',
      route: `${ApiRoute.LOTS}/${lotId}/file`,
      excludeContentType: true,
      body: formData,
      additionalHeaders: {
        'Content-Type': 'multipart/form-data'
      },
    });
  },

  editLot: async (
    id: string,
    data: Pick<ILot, 'artistFullName' | 'name' | 'size' | 'techniqueAndMaterials' | 'index'>,
  ) => {
    const bodyData = JSON.stringify(data);
    return await callApi<Omit<ILot, 'id'>>({
      method: 'PATCH',
      route: `${ApiRoute.LOTS}/${id}`,
      body: bodyData,
    });
  },

  makeBet: async (lotId: string, amount: number, betId: string): Promise<IBet> => {
    const bodyData = JSON.stringify({
      amount
    });
    return await callApi({
      route: `${ApiRoute.USER_LOTS}/${lotId}/bet/${betId}`,
      method: 'POST',
      body: bodyData
    });
  },

  getMyBets: async (): Promise<IMyBet[]> => {
    try {
      return await callApi({
        route: `${ApiRoute.USER_LOTS}/my-bets`,
      });
    } catch (error) {
      console.error('LotService: getMyBets', error);
      return [];
    }
  },

  getMyLots: async (): Promise<IMyLot[]> => {
    try {
      return await callApi({
        route: `${ApiRoute.USER_LOTS}/my-art`,
      });
    } catch (error) {
      console.error('LotService: getMyLots', error);
      return [];
    }
  },
};
