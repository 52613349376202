 
import { Flex, message } from 'antd';
import { type FC, useEffect, useState } from 'react';
import { AuctionCard } from '../../../../common/components/AuctionCard';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../../routes/routeNames';
import { type IAuction } from 'src/common/types';
import { auctionService } from 'src/common/services/auctions';
import { io } from 'socket.io-client';
import { remindersService } from 'src/common/services/reminders';
import JoinButton from 'src/common/components/JoinButton';
import { type IReminder } from 'src/types';

export const socket = io(process.env.REACT_APP_API_URL ?? '', {
  autoConnect: true,
  transports: ['websocket'],
});

export interface IFutureReminder extends IAuction {
  hasUserReminder?: boolean
}

export const AuctionsList: FC = () => {
  const navigate = useNavigate()
  const [auctions, setAuctions] = useState<IFutureReminder[] | undefined>(undefined);

  const getReminders = async (auctions: IAuction[]) => {
    const USER_REMINDERS = await remindersService.getReminders();

    if (USER_REMINDERS) {
      const newAuctionsWithReminders = auctions?.map((auction) => {
        const auctionWithReminder: IReminder | undefined = USER_REMINDERS.find((reminder) => {
          if (reminder.refType === 'AUCTION' && reminder.entity.id === auction.id) {
            return auction;
          }
          return false;
        })
        if (auctionWithReminder) {
          return { ...auction, hasUserReminder: true };
        } else {
          return auction
        }
      })
      setAuctions(newAuctionsWithReminders.sort((a, b) => {
        return new Date(b.datetime).getTime() - new Date(a.datetime).getTime();
      }))
    }
  };

  useEffect(() => {
    socket.on('connect', () => {
      const token = localStorage.getItem('token');
      const auth = socket.emit('auth', { token });
    });

    socket.on('connect_error', (message) => {
      console.info('connect_error', message);
    });

    socket.on('auth_success', (res) => {
      if (res.userId) localStorage.setItem('userId', res.userId)
    });

    socket.on('auth_failed', (message) => {
      console.info('auth_failed', message);
    });
  }, [])

  useEffect(() => {
    const getAuctions = async () => {
      const PUBLISHED_AUCTIONS = await auctionService.getPublishedAuctions();

      if (PUBLISHED_AUCTIONS?.[0]) {
        const filteredAuctions = PUBLISHED_AUCTIONS.filter((auction) => {
          return new Date(auction.datetime).getUTCDate() === new Date().getUTCDate() && !auction.isFinished;
        }).sort((a, b) => {
          return new Date(b.datetime).getTime() - new Date(a.datetime).getTime();
        })
        setAuctions(filteredAuctions)
        getReminders(filteredAuctions);
      }
    };
    getAuctions()

    setInterval(() => {
      getAuctions()
    }, 60000)

    return () => {
      setAuctions([]);
    }
  }, [])

  const handleRemind = async (auctionId: string) => {
    const res = await remindersService.auctionStart(auctionId)
    if (!res) {
      message.success('Напоминание о Начале Аукциона успешно создано!')
      const auctionsWithReminder = auctions?.map((auction) => {
        if (auction.id === auctionId) {
          return { ...auction, hasUserReminder: true }
        }
        return auction;
      })
      setAuctions(auctionsWithReminder)
    } else {
      message.error(res)
    }
  };

  const handleCheckLots = (id: string) => {
    navigate('/auctions/' + id);
  };

  return (
      <Flex vertical align="center">
        {auctions?.map((auction: IFutureReminder) => {
          return (
            <AuctionCard
              gallery={auction.name}
              key={auction.id}
              datetime={auction.datetime}
              imageSrc={auction.pathToCover}
              isAuctionEnded={auction.isFinished}
              type={auction.isCurrent ? 'active' : 'future'}
              cardAction={
              auction.isCurrent
                ? <JoinButton handleClick={() => {
                  if (auction.isCurrent) {
                    navigate(ROUTES.PARTICIPATE.URL + `?auction=${auction.id}`);
                  }
                }}/>
                : <>
              <JoinButton handleClick={() => { handleCheckLots(auction.id); }} type='default' title='Смотреть лоты'/>
              <JoinButton disabled={auction.hasUserReminder} handleClick={ () => { handleRemind(auction.id); }} type='default' title='Напомнить'/>
            </>
            }
            />
          );
        })}
    </Flex>
  );
};
