import { ExclamationCircleOutlined } from '@ant-design/icons';
import { message, Modal } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auctionService } from '../../../../common/services/auctions';
import { lotService } from '../../../../common/services/lots';
import { ROUTES } from '../../../../routes/routeNames';
import { useAuctionsStore } from '../../store/useAuctionsStore';
import { useToken } from 'src/common/hooks/useToken';
import { useValidateLots } from '../../../../common/hooks/useValidateLots';
import { type ValidationError } from 'yup';

export const useCreateAuction = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [modal, contextHolder] = Modal.useModal();

  const { auctionGeneralInfo, createLots, setAuctionGeneralInfo, setLotsToCreate } = useAuctionsStore(
    (state) => state,
  );
  const navigate = useNavigate();
  const { adminToken } = useToken()
  const { validateLots } = useValidateLots()

  const handleNextStep = useCallback(() => {
    setCurrentStep((prevState) => prevState + 1);
  }, []);

  const handleArrowPress = useCallback(() => {
    if (currentStep > 0) {
      setCurrentStep((prevState) => prevState - 1);
      return;
    }

    setAuctionGeneralInfo(undefined);
    navigate(ROUTES.ADMIN.URL);
  }, [currentStep, navigate, setAuctionGeneralInfo]);

  const displayStepTitle = () => {
    if (currentStep > 0) {
      return 'Шаг 2. Добавьте лоты';
    }

    return 'Шаг 1. Основная информаця';
  };

  const handlePublishAuction = useCallback(async () => {
    if (auctionGeneralInfo) {
      const { name, datetime, file } = auctionGeneralInfo;

      const validationErrors = await validateLots(createLots);

      if (validationErrors) {
        message.error('Ошибка при создании лотов')
        return;
      }

      auctionService
        .createAuction({ name, datetime, file })
        .then((res) => {
          if (createLots?.length) {
            const createLotPromises = createLots.map(async (lot, index) => {
              try {
                await lotService.createLot({
                  auctionId: res.id,
                  artistFullName: lot.artistFullName,
                  size: lot.size,
                  techniqueAndMaterials: lot.techniqueAndMaterials,
                  name: lot.name,
                  file: lot.file,
                  lotStartAmount: lot.lotStartAmount,
                  index: lot.index || index,
                });
              } catch (e: unknown) {
                if (e instanceof Error) {
                  message.error(e.message[0]);
                }
              }
            });

            Promise.all(createLotPromises)
              .then(() => {
                auctionService?.publishAuction(res.id, adminToken)
                  .then(() => {
                    message.success('Аукцион успешно опубликован!')
                    setTimeout(() => {
                      navigate(ROUTES.ADMIN.URL)
                    }, 3000);
                  })
                  .catch((e) => message.error(e.message));
              });
          } else {
            auctionService?.publishAuction(res.id, adminToken)
              .then(() => {
                message.success('Аукцион успешно опубликован!')
                setTimeout(() => {
                  navigate(ROUTES.ADMIN.URL)
                }, 3000);
              })
              .catch((e) => message.error(e.message));
          }
        })
        .catch((e) => message.error(e.message));
    }
  }, [auctionGeneralInfo, createLots, navigate]);

  const handleSubmit = useCallback(async () => {
    setIsLoading(true);
    if (auctionGeneralInfo) {
      const { name, datetime, file } = auctionGeneralInfo;

      const validationErrors = await validateLots(createLots) as ValidationError[] | null;

      if (validationErrors) {
        message.error('Ошибка при создании лотов: ' + validationErrors[0].message)
        console.log(validationErrors);
        setIsLoading(false); // Установка isLoading(false) в случае ошибки
        return;
      }

      !validationErrors && auctionService
        .createAuction({
          name,
          datetime,
          file,
        })
        .then(async (res) => {
          if (createLots?.length) {
            return await Promise.all(
              createLots.map(async (lot, index) => {
                await lotService
                  .createLot({
                    auctionId: res.id,
                    artistFullName: lot.artistFullName,
                    size: lot.size,
                    techniqueAndMaterials: lot.techniqueAndMaterials,
                    name: lot.name,
                    file: lot.file,
                    lotStartAmount: lot.lotStartAmount,
                    index,
                  })
                  .catch((e) => message.error(e.message));
              }),
            );
          } else {
            await Promise.resolve();
          }
        })
        .then(() => {
          message.success('Аукцион успешно сохранен!')
          setTimeout(() => {
            navigate(ROUTES.ADMIN.URL)
          }, 3000);
        })
        .catch((e) => message.error(e.message))
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [auctionGeneralInfo, createLots]);

  const handleSubmitPublishing = useCallback(() => {
    modal.confirm({
      title: 'Опубликовать аукцион',
      icon: <ExclamationCircleOutlined />,
      content:
        'Вы действительно хотите опубликовать этот аукцион? Он сразу будет отображаться у пользователей',
      okText: 'Опубликовать',
      cancelText: 'Отменить',
      onOk: handlePublishAuction,
    });
  }, [handlePublishAuction, modal]);

  useEffect(() => {
    return () => {
      setAuctionGeneralInfo(undefined);
      setLotsToCreate([])
    }
  }, []);

  return {
    createLots,
    currentStep,
    handleNextStep,
    handleArrowPress,
    displayStepTitle,
    handlePublishAuction,
    isLoading,
    handleSubmit,
    handleSubmitPublishing,
    contextHolder,
  };
};
