import { callApi } from '../../../api/callApi';
import { ApiRoute } from '../../constans/apiRoutes';

export interface IMessage {
  id: string
  tgUserId: string
  tgUsername: string
  phone: string
  messages: IMessageItem[]
  deletedAt: null | string
  createdAt: string
  updatedAt: string
}

export interface IMessageItem {
  id: string
  direction: number
  text: string
  userId: string
  deletedAt: null | string
  createdAt: string
  updatedAt: string
}

export const messagesService = {
  getMessages: async (): Promise<IMessage[]> => {
    try {
      const res: IMessage[] = await callApi({
        route: `${ApiRoute.MESSAGES}`,
      })
      return res;
    } catch (error) {
      console.error('Reminder Service: getReminders', error);
      return [];
    }
  },
  postOutgoing: async (text: string, toUserId: string): Promise<IMessageItem | null> => {
    try {
      const res: IMessageItem = await callApi({
        route: `${ApiRoute.MESSAGES}/outgoing`,
        body: {
          text,
          toUserId
        },
        method: 'POST'
      })
      return res;
    } catch (error) {
      console.error('Reminder Service: getReminders', error);
      return null;
    }
  },
  postBroadcast: async (text: string): Promise<IMessage | null> => {
    try {
      const res: IMessage = await callApi({
        route: `${ApiRoute.MESSAGES}/broadcast`,
        body: {
          text
        },
        method: 'POST'
      })
      return res;
    } catch (error) {
      console.error('Reminder Service: getReminders', error);
      return null;
    }
  },
}
