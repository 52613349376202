import { type ReactNode } from 'react'
import styles from './Wrapper.module.scss'
import Navbar from '../NavBar';
import Header from '../Header';

export default function Wrapper ({ children, title }: { children: ReactNode, title?: string }) {
  return (
    <div className={styles.wrapper}>
      {title && <Header title={title} />}
      <div className={styles.container}>
        {children}
      </div>
      <Navbar />
    </div>
  )
}
