import { useCallback, useEffect, useLayoutEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { auctionService } from '../../../../common/services/auctions';
import { lotService } from '../../../../common/services/lots';
import { ROUTES } from '../../../../routes/routeNames';
import { type RouteParams } from '../../../../types/route';
import { useAuctionsStore } from '../../store/useAuctionsStore';
import { useToken } from '../../../../common/hooks/useToken';
import { message } from 'antd';

export const useReadAuction = () => {
  const { id: pageId } = useParams<RouteParams<'READ_AUCTION'>>();
  const { updateAuctionInfo, updateLotsInfo } = useAuctionsStore((state) => state);
  const { adminToken } = useToken()
  const navigate = useNavigate();

  const handleBackPress = useCallback(() => {
    navigate(ROUTES.ADMIN.URL);
  }, [navigate, updateAuctionInfo, updateLotsInfo]);

  useLayoutEffect(() => {
    if (pageId) {
      auctionService.getAuctionByID(pageId, adminToken).then((res) => {
        updateAuctionInfo(res);
        if (res) { void lotService.getAdminLotsByAuctionId(res.id).then(res => { updateLotsInfo(res); }) }
      }).catch(e => message.error(e as string));
    }
  }, [pageId, updateAuctionInfo, updateLotsInfo]);

  useEffect(() => {
    return () => {
      updateAuctionInfo(null);
      updateLotsInfo([]);
    }
  }, [updateAuctionInfo, updateLotsInfo]);
  return { handleBackPress };
};
