import { CloseOutlined } from '@ant-design/icons';
import { Flex, Typography } from 'antd';
import { type FC } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { AddMoreButton } from '../../../../../common/components/AddMoreButton';
import { ImageUpload } from '../../../../../common/components/ImageUpload';
import { SidedLayout } from '../../../../../common/layouts/SidedLayot';
import styles from '../../../EditAuction/EditLots/styles.module.scss';
import { useCreateLot } from '../../../EditAuction/hooks/useCreateLot';
import { LotInputFields } from '../../../components/LotInputFields';
import { useAuctionsStore } from '../../../store/useAuctionsStore';

const LeftLayout: FC = () => {
  const createLots = useAuctionsStore((state) => state.createLots);

  const { itemRefs, handleDeleteLot, imagePaths, handleImageChange, handleInput, addNewLot } =
    useCreateLot();

  return (
    <Flex vertical gap={20}>
      <TransitionGroup>
        {createLots.map((el, id) => {
          return (
            <CSSTransition key={el.id} timeout={300} classNames={styles}>
              <div>
                <Flex justify="space-between">
                  <Typography>{`Лот № ${id + 1}`} </Typography>
                  <CloseOutlined onClick={handleDeleteLot(el.id)} />
                </Flex>
                <Flex gap={16} className={styles.container}>
                  <div>
                    <ImageUpload
                      shouldRenderPreview={!!imagePaths[id]}
                      name="file"
                      imagePreview={imagePaths[id]}
                      ref={itemRefs.current[id]}
                      className={styles.uploadContainer}
                      onChange={handleImageChange(itemRefs.current[id], id)}
                    />
                  </div>
                  <LotInputFields lot={el} handleInputChange={handleInput} />
                </Flex>
              </div>
            </CSSTransition>
          );
        })}
      </TransitionGroup>
      <AddMoreButton onClick={addNewLot} buttonText="Добавить лот" />
    </Flex>
  );
};

export const CreateLot = () => {
  return <SidedLayout leftChildren={<LeftLayout />} />;
};
