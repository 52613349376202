import Button, { type ButtonType } from 'antd/es/button'
import React from 'react'
import styles from './JoinButton.module.scss';

interface IJoinButton {
  handleClick: () => void
  type?: ButtonType
  title?: string
  disabled?: boolean
}

export default function CommonButton ({ handleClick, title = 'Участвовать', type = 'primary', disabled = false }: IJoinButton) {
  return (
    <Button type={type} disabled={disabled} className={type === 'primary' ? styles.button : styles.secondary} onClick={handleClick}>{title}</Button>
  )
}
