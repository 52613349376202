import { Flex } from 'antd';
import styles from './Header.module.scss';
// import Headphones from '../../../assets/icons/Headphones.svg';
import Info from '../../../assets/icons/Info.svg';
import { Link } from 'react-router-dom';

interface IHeader {
  title: string
}

export default function index ({ title }: IHeader) {
  // const handleHelp = () => {}

  const handleInfo = () => {
  }

  return (
    <header className={styles.header}>
      <Flex align='start' justify='between' className={styles.subwrapper}>
        <h1 className={styles.title}>{title}</h1>
        <Flex gap={8} align='center'>
          {/* <img src={Headphones} alt='Помощь' onClick={handleHelp} /> */}
          <Link to='/about' style={{
            marginTop: '2px',
          }}>
            <img src={Info} alt='Инфо' onClick={handleInfo} />
          </Link>
        </Flex>
      </Flex>
    </header>
  )
}
