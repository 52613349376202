import { Card, Flex, Image, Typography } from 'antd';
import { type FC, type ReactNode } from 'react';
import { renderLabel } from '../../helpers/renderLabel';
import { Label, type LabelProps } from '../Label';
import styles from './styles.module.scss';
import { Link } from 'react-router-dom';
import { ROUTES } from 'src/routes/routeNames';
import { REACT_APP_API_URL } from 'src/common/constans/apiRoutes';

export interface AuctionCardProps {
  children?: ReactNode
  datetime?: string
  gallery: string
  imageSrc?: string | null
  isAuctionEnded?: boolean
  cardType?: 'lot' | 'auction'
  type?: LabelProps['type']
  onClick?: () => void
  cardAction?: ReactNode
  materials?: string
  size?: string
  artistFullName?: string
  bet?: string
  isLeading?: boolean
}

export const AuctionCard: FC<AuctionCardProps> = ({
  imageSrc,
  gallery,
  bet,
  materials,
  cardType = 'auction',
  type,
  onClick,
  cardAction,
  isAuctionEnded = false,
  isLeading,
  size,
  datetime,
  artistFullName,
  children
}) => {
  const date = datetime ? new Date(datetime).toLocaleDateString() : '';
  const time = datetime ? new Date(datetime).toLocaleTimeString() : '';
  return (
    <Card
      hoverable
      className={styles.container}
      cover={imageSrc &&
      <Image width={327} height={327} preview={{
        mask: false,
      }}
      alt={`${gallery} Image`}
      src={REACT_APP_API_URL + '/' + imageSrc}
      onClick={onClick} style={{
        objectFit: 'contain',
      }} />}
    >
      <Flex vertical className={styles.wrapper}>
        {children}
        <Typography className={styles.galleryName}>{gallery}</Typography>
        {artistFullName && <Typography className={styles.materials}>{artistFullName}</Typography>}
        {materials && <Typography className={styles.materials}>Материалы: {materials}</Typography>}
        {size && <Typography className={styles.materials}>Размер: {size}</Typography>}
        {datetime && <Typography className={styles.date}>{`${date} | ${time}`}</Typography>}
        {bet && bet.includes('Старт:') && <Typography className={styles.nobodybet}>Еще никто не сделал ставку</Typography> }
        {isLeading && <Typography className={styles.leading}>Ваша ставка лидирует</Typography>}
        {!isLeading && bet && bet.includes('Текущая цена лота:') && <Typography className={styles.notleading}>Ваша ставка перебита</Typography>}
        {bet &&
          <>
            <Typography className={styles.bet}>{bet}</Typography>
          </>
        }

        {cardType === 'auction' && isAuctionEnded && <>
          <Typography className={styles.auctionEnded}>Аукцион закончился</Typography>
          <Typography style={{ marginTop: '10px' }}>
          В ближайшее время ваши покупки появятся во вкладке
          <Link to={ROUTES.MY_LOTS.URL}> Мои лоты</Link>
          </Typography>
        </>}
        {cardType === 'auction' && type === 'active'
          ? (
          <div className={styles.labelContainer}>
            <Label type={type}>{renderLabel(type)}</Label>
          </div>
            )
          : <>
          </>}
          {cardAction}
      </Flex>
    </Card>
  );
};
