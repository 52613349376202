import { generatePath } from 'react-router';

import { ROUTES } from '../../routes/routeNames';
import { type RouteParams, type RouteKeys } from '../../types/route';

/**
 * Build full url with params
 *
 * E.g.: makeUrl('EVENTS_POST', { postId: '123' });
 */
export const makeURL = <TKey extends RouteKeys>(
  routeKey: TKey,
  params?: RouteParams<TKey>,
): string => {
  const path = ROUTES[routeKey].URL;

  return generatePath(path, params);
};
