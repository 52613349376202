import { Flex, message } from 'antd';
import { useEffect, useState, type FC } from 'react';
import { AuctionCard } from '../../../../common/components/AuctionCard';
import JoinButton from 'src/common/components/JoinButton';
import { useNavigate } from 'react-router-dom';
import { remindersService } from 'src/common/services/reminders';
import { type IAuction } from 'src/common/types';
import { auctionService } from 'src/common/services/auctions';
import { type IReminder } from 'src/types';
import { socket } from '../AuctionsList';
import { ROUTES } from 'src/routes/routeNames';

export interface IFutureReminder extends IAuction {
  hasUserReminder?: boolean
}

export const FutureAuctionsList: FC = () => {
  const navigate = useNavigate();
  const [auctions, setAuctions] = useState<IFutureReminder[]>();

  const getReminders = async (auctions: IAuction[]) => {
    const USER_REMINDERS = await remindersService.getReminders();

    if (USER_REMINDERS) {
      const newAuctionsWithReminders = auctions?.map((auction) => {
        const auctionWithReminder: IReminder | undefined = USER_REMINDERS.find((reminder) => {
          if (reminder.refType === 'AUCTION' && reminder.entity.id === auction.id) {
            return auction;
          }
          return false;
        })
        if (auctionWithReminder) {
          return { ...auction, hasUserReminder: true };
        } else {
          return auction
        }
      })
      setAuctions(newAuctionsWithReminders)
    }
  };

  useEffect(() => {
    socket.on('connect', () => {
      const token = localStorage.getItem('token');
      const auth = socket.emit('auth', { token });
    });

    socket.on('connect_error', (message) => {
      console.info('connect_error', message);
    });

    socket.on('auth_success', (res) => {
      if (res.userId) localStorage.setItem('userId', res.userId)
    });

    socket.on('auth_failed', (message) => {
      console.info('auth_failed', message);
    });
  }, [])

  useEffect(() => {
    const getAuctions = async () => {
      const PUBLISHED_AUCTIONS = await auctionService.getPublishedAuctions();

      if (PUBLISHED_AUCTIONS) {
        const filteredAuctions = PUBLISHED_AUCTIONS.filter((auction) => {
          return !auction.isFinished;
        }).sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
        setAuctions(filteredAuctions)
        getReminders(filteredAuctions)
      }
    };
    getAuctions()
  }, [])

  const handleRemind = async (auctionId: string) => {
    const res = await remindersService.auctionStart(auctionId)
    if (!res) {
      message.success('Напоминание о Начале Аукциона успешно создано!')
      const auctionsWithReminder = auctions?.map((auction) => {
        if (auction.id === auctionId) {
          return { ...auction, hasUserReminder: true }
        }
        return auction;
      })
      setAuctions(auctionsWithReminder)
    } else {
      message.error(res)
    }
  };

  const handleCheckLots = (id: string) => {
    navigate('/auctions/' + id);
  };

  return (
    <Flex vertical gap='middle' align='center' justify='center'>
    {auctions?.map((auction) => {
      return (
        <AuctionCard
        key={auction.id}
        datetime={auction.datetime}
        imageSrc={auction.pathToCover}
        gallery={auction.name}
        type={auction.isCurrent ? 'active' : 'future'}
        cardType='auction'
        cardAction={
          auction.isCurrent
            ? <JoinButton handleClick={() => {
              if (auction.isCurrent) {
                navigate(ROUTES.PARTICIPATE.URL + `?auction=${auction.id}`);
              }
            }}/>
            : <>
          <JoinButton handleClick={() => { handleCheckLots(auction.id); }} type='default' title='Смотреть лоты'/>
          <JoinButton disabled={auction.hasUserReminder} handleClick={ () => { handleRemind(auction.id); }} type='default' title='Напомнить'/>
        </>
        }
        />
      )
    })}
    </Flex>
  )
}
