import { Flex, Typography } from 'antd';
import { type FC, useMemo } from 'react';

import { ImagePreview } from '../../../common/components/ImagePreview';
import { Label } from '../../../common/components/Label';
import { LotPreview } from '../../../common/components/LotPreview';
import { PageTitle } from '../../../common/components/PageTitle';
import { REACT_APP_API_URL } from '../../../common/constans/apiRoutes';
import { renderLabel } from '../../../common/helpers/renderLabel';
import { useDateTime } from '../../../common/hooks/useDateTime';
import { SidedLayout } from '../../../common/layouts/SidedLayot';
import { useAuctionsStore } from '../store/useAuctionsStore';

import { useReadAuction } from './hooks/useReadAuction';
import styles from './styles.module.scss';

const LeftLayout: FC = () => {
  const { auction } = useAuctionsStore((state) => state);
  const [parseDateTime, calculateLabel] = useDateTime();

  const renderText = useMemo(() => {
    if (!auction?.datetime) {
      return null;
    }

    const label = auction ? calculateLabel(auction?.datetime) : undefined;

    const { formattedDate, time } = parseDateTime(auction?.datetime);

    return { label, time, formattedDate };
  }, [auction, calculateLabel, parseDateTime]);

  return (
    <Flex gap={16}>
      <ImagePreview src={`${REACT_APP_API_URL}/${auction?.pathToCover}`} />
      {renderText && (
        <Flex vertical>
          <Typography className={styles.previewTitle}>{auction?.name}</Typography>
          <Typography
            className={styles.datetime}
          >{`${renderText.formattedDate} | ${renderText.time}`}</Typography>
          {renderText.label !== undefined && (
            <Label type={renderText.label}>{renderLabel(renderText.label)}</Label>
          )}
        </Flex>
      )}
    </Flex>
  );
};

const RightLayout = () => {
  const { lots } = useAuctionsStore((state) => state);

  return (
    <Flex vertical gap={8}>
      {lots.map(({ pathToCover, name, size, artistFullName, techniqueAndMaterials, id }, index) => {
        return (
          <LotPreview
            lotNumber={`Лот №${index + 1}`}
            key={id}
            src={pathToCover}
            size={size}
            name={name}
            artistFullName={artistFullName}
            techniqueAndMaterials={techniqueAndMaterials}
          />
        );
      })}
    </Flex>
  );
};

export const ReadAuction = () => {
  const { handleBackPress } = useReadAuction();
  return (
    <Flex vertical gap={20}>
      <PageTitle onClickArrow={handleBackPress} withBackArrow title="Аукцион" />
      <SidedLayout leftChildren={<LeftLayout />} rightChildren={<RightLayout />} />
    </Flex>
  );
};
