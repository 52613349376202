export const ROUTES = {
  HOME: {
    URL: '/',
  },
  ADMIN: {
    URL: '/admin',
  },
  MESSAGES: {
    URL: '/admin/messages',
  },
  PARTICIPANTS: {
    URL: '/admin/participants',
  },
  CREATE_AUCTION: {
    URL: '/admin/create',
  },
  AUCTION: {
    URL: '/auctions/:id',
    PARAMS: { id: '' },
  },
  EDIT_AUCTION: {
    URL: '/admin/edit/:id',
    PARAMS: { id: '' },
  },
  READ_AUCTION: {
    URL: '/admin/details/:id',
    PARAMS: { id: '' },
  },
  START: {
    URL: '/',
  },
  REMINDERS: {
    URL: '/reminders'
  },
  ABOUT: {
    URL: '/about'
  },
  NOTIFICATIONS: {
    URL: '/notifications'
  },
  MY_LOTS: {
    URL: '/my_lots'
  },
  MY_BETS: {
    URL: '/my_bets'
  },
  AUCTIONS: {
    URL: '/auctions',
  },
  LOGIN_PAGE: {
    URL: '/login',
  },
  PARTICIPATE: {
    URL: '/participate'
  },
  METRIC: {
    URL: '/admin/metric/:id',
    PARAMS: { id: '' },
  },
};
