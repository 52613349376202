import Flex from 'antd/es/flex'
import { useEffect, useState } from 'react'
import { AuctionCard } from 'src/common/components/AuctionCard'
import Wrapper from 'src/common/components/Wrapper'
import { remindersService } from 'src/common/services/reminders'
import { type IReminder } from 'src/types'
import styles from './styles.module.scss'

export default function Reminders () {
  const [reminders, setReminders] = useState<IReminder[]>();

  const getReminders = async () => {
    try {
      const data: IReminder[] = await remindersService.getReminders();
      setReminders(data);
    } catch (error) {
      console.error('Error: getReminders', error)
    }
  };

  const postReadReminders = async () => {
    try {
      await remindersService.postReadReminders();
    } catch (error) {
      console.error('Error: getReminders', error)
    }
  };

  useEffect(() => {
    getReminders();
    postReadReminders();
  }, []);

  return (
    <Wrapper title='Напоминания'>
      <p className={styles.notreaded}>
        {reminders?.filter((reminder) => !reminder.isRead).length}
      </p>
      <Flex vertical align="center" gap={20} style={{
        width: '100%',
        marginTop: 10
      }}>
        {reminders?.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()).map((reminder) => {
          return <AuctionCard
          key={reminder.id}
          datetime={reminder.createdAt}
          imageSrc={reminder.entity.pathToCover}
          gallery={reminder.entity.name + ' ' + reminder.type}
          cardType='auction'
          />
        })}
      </Flex>
    </Wrapper>
  )
}
