import { ConfigProvider } from 'antd';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Routes } from './routes/Routes';
import { theme } from './theme';
import 'normalize.css';

const root = ReactDOM.createRoot(document.getElementById('root')!);

declare global {
  interface Window {
    Telegram: any
  }
}

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ConfigProvider theme={theme}>
        <Routes />
      </ConfigProvider>
    </BrowserRouter>
  </React.StrictMode>,
);
