import { Tabs } from 'antd';
import { TabsData } from './tabsData';
import classes from './Home.module.scss'
import Wrapper from 'src/common/components/Wrapper';

export const Home = () => {
  return (
    <Wrapper title='Аукционы'>
      <div className={classes.subwrapper}>
        <Tabs defaultActiveKey="0" items={TabsData} />
      </div>
    </Wrapper>
  )
};
