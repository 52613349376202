import Flex from 'antd/es/flex';
import { useEffect, useState } from 'react';
import { AuctionCard } from 'src/common/components/AuctionCard';
import Wrapper from 'src/common/components/Wrapper';
import { notificationsService } from 'src/common/services/notifications';
import { type INotification } from 'src/types';

export default function Notifications () {
  const [notifications, setNotifiications] = useState<INotification[]>([]);

  const getNotifications = async () => {
    try {
      const data = await notificationsService.getNotifications();
      if (data[0]) setNotifiications(data);
    } catch (error) {
      console.error('Error: getNotifications')
    }
  };

  useEffect(() => {
    // getNotifications();
  }, []);

  return (
    <Wrapper title='Уведомления'>
      <Flex vertical align="center" gap={20} style={{
        width: '100%',
        marginTop: 10
      }}>
        {notifications.length > 0 && notifications?.map((reminder) => {
          return <AuctionCard
          key={reminder.id}
          datetime={reminder.createdAt}
          imageSrc={reminder?.entity?.pathToCover}
          gallery={reminder.text}
          cardType='auction'
          />
        })}
      </Flex>
    </Wrapper>
  );
}
