import { type Tab } from 'rc-tabs/lib/interface';
import { AuctionsList } from './AuctionsList';
import { TabPublishingParams } from './hooks/useAuctionsList';

export const TabsData: Tab[] = [
  {
    key: '0',
    label: 'Опубликованные',
    children: <AuctionsList publishingParam={TabPublishingParams.Published} />,
  },
  {
    key: '1',
    label: 'Не опубликованные',
    children: <AuctionsList publishingParam={TabPublishingParams.Unpublished} />,
  },
  {
    key: '2',
    label: 'Прошедшие',
    children: <AuctionsList publishingParam={TabPublishingParams.Passed} />,
  },
];
