import { ExclamationCircleOutlined } from '@ant-design/icons';
import { message, Modal } from 'antd';
import { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CardActionButtons } from '../../../../common/components/CardActionButtons';
import { UnpublishedCardButtons } from '../../../../common/components/UnpublishedCardActionButtons';
import { makeURL } from '../../../../common/helpers/makeUrl';
import { auctionService } from '../../../../common/services/auctions';
import { useAuctionsStore } from '../../store/useAuctionsStore';
import { ADMIN_TOKEN_KEY } from '../../../../common/constans/localStorageKeys';
import { useToken } from '../../../../common/hooks/useToken';
import { type IAdminAuction } from 'src/common/types';

export enum TabPublishingParams {
  Published = 'published',
  Unpublished = 'unpublished',
  Passed = 'passed',
}

export const useAuctionsList = (publishParam: TabPublishingParams) => {
  const [isLoading, setIsLoading] = useState(false);
  const [modal, contextHolder] = Modal.useModal();
  const { auctions, setAuctions } = useAuctionsStore((state) => state);
  const { adminToken } = useToken()
  const navigate = useNavigate();

  const sortAuctionsByDateTime = (
    fetchedAuctions: IAdminAuction[],
  ): IAdminAuction[] => {
    const currentDate = new Date();

    fetchedAuctions.sort((a, b) => {
      const dateA = new Date(a.datetime);
      const dateB = new Date(b.datetime);
      const diffA = Math.abs(dateA.getTime() - currentDate.getTime());
      const diffB = Math.abs(dateB.getTime() - currentDate.getTime());
      return diffA - diffB;
    });

    return fetchedAuctions;
  };

  const handleEditPress = useCallback(
    (id: string) => () => {
      navigate(makeURL('EDIT_AUCTION', { id }));
    },
    [navigate],
  );

  const handleCardPress = useCallback(
    (id: string) => () => {
      if (publishParam !== TabPublishingParams.Passed) {
        navigate(makeURL('READ_AUCTION', { id }));
      } else {
        navigate(makeURL('METRIC', { id }))
      }
    },
    [navigate, publishParam],
  );

  const deleteAuction = useCallback(
    (id: string) => () => {
      const filteredAuctions = auctions.filter((el) => el.id !== id);
      auctionService.deleteAuctionById(id, adminToken).catch((e: Error) => message.error(e.message));
      setAuctions(filteredAuctions);
    },

    [auctions, setAuctions],
  );

  const publishAuction = useCallback(
    (id: string) => () => {
      const filteredAuctions = auctions.filter((el) => el.id !== id);
      auctionService?.publishAuction(id, adminToken).then(() => {
        setAuctions(filteredAuctions);
        message.success('Аукцион успешно опубликован!')
      });
    },
    [auctions, setAuctions],
  );

  const handleDelete = useCallback(
    (id: string) => () => {
      modal.confirm({
        title: 'Удалить аукцион',
        icon: <ExclamationCircleOutlined />,
        content: 'Вы действительно хотите удалить этот аукцион?',
        okText: 'Удалить',
        cancelText: 'Отменить',
        onOk: deleteAuction(id),
      });
    },
    [deleteAuction, modal],
  );
  const handlePublishAuction = useCallback(
    (id: string) => () => {
      modal.confirm({
        title: 'Опубликовать аукцион',
        icon: <ExclamationCircleOutlined />,
        content:
          'Вы действительно хотите опубликовать этот аукцион? Он сразу будет отображаться у пользователей',
        okText: 'Опубликовать',
        cancelText: 'Отменить',
        onOk: publishAuction(id),
      });
    },
    [modal, publishAuction],
  );

  const renderCardAction = useCallback(
    (id: string) => {
      if (publishParam === TabPublishingParams.Published) {
        return (
          <CardActionButtons
            onDeletePress={handleDelete(id)}
            onEditPress={handleEditPress(id)}
          />
        );
      }
      if (publishParam === TabPublishingParams.Passed) {
        return <CardActionButtons isPassed onResultsPress={handleCardPress(id)}
        />;
      }

      return (
        <UnpublishedCardButtons
          onUpload={handlePublishAuction(id)}
          onDelete={handleDelete(id)}
          onEdit={handleEditPress(id)}
        />
      );
    },
    [handleDelete, handleEditPress, handlePublishAuction, publishParam],
  );

  const emptyDescription = useMemo(() => {
    if (publishParam === TabPublishingParams.Published) {
      return 'Нет опубликованных аукционов';
    }
    if (publishParam === TabPublishingParams.Passed) {
      return 'Нет аукционов ';
    }
    return 'Нет добавленных аукционов';
  }, [publishParam]);

  useLayoutEffect(() => {
    setIsLoading(true);
    const token = localStorage.getItem(ADMIN_TOKEN_KEY)

    if (token !== null) {
      switch (publishParam) {
        case TabPublishingParams.Published:
          auctionService
            .getAuctions(true, false)
            .then((res) => {
              const sortedAuctions = sortAuctionsByDateTime(res);
              setAuctions(sortedAuctions);
            })
            .finally(() => {
              setIsLoading(false);
            });
          break;
        case TabPublishingParams.Passed:
          auctionService
            .getAuctions(true, true)
            .then((res) => {
              setAuctions(res);
            })
            .finally(() => {
              setIsLoading(false);
            });
          break;
        case TabPublishingParams.Unpublished:
          auctionService
            .getAuctions(false, false)
            .then((res) => {
              setAuctions(res);
            })
            .finally(() => {
              setIsLoading(false);
            });
          break;
        default:
          setIsLoading(false);
      }
    }
  }, [publishParam, setAuctions]);

  return {
    isLoading,
    emptyDescription,
    renderCardAction,
    contextHolder,
    handleCardPress,
  };
};
