import Wrapper from '../../common/components/Wrapper';
import { Button, Flex, Typography, message } from 'antd';
import { AuctionCard } from '../../common/components/AuctionCard';
import styles from './styles.module.scss'
import { LeftOutlined } from '@ant-design/icons';
import { useCallback, useEffect, useState } from 'react';
import { type IBet, type ILot } from 'src/common/types';
import { socket } from '../Home/components/AuctionsList';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ROUTES } from 'src/routes/routeNames';

interface LotFinished {
  lotId: string
  userId: string
}

interface IAuctionLot extends ILot {
  currentBet: IBet
  ttlMilliseconds: number
}

export const Participate = () => {
  const userId = localStorage.getItem('userId');
  const [lot, setLot] = useState<IAuctionLot>();
  const [isUserWinner, setIsUserWinner] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const [time, setTime] = useState(0);
  const navigate = useNavigate()

  const handleButtonPress = useCallback(async (betId: string) => {
    socket.emit('lot_bet', { betId });
  }, [])

  const handleGoBack = useCallback(() => {
    navigate(ROUTES.HOME.URL)
  }, [])

  const formatTime = (time: number): string => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;

    if (time > 0) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime - 1);
      }, 1000) as NodeJS.Timeout;
    }

    return () => {
      if (interval) clearInterval(interval);
      localStorage.removeItem('leftTimeAuction')
    };
  }, [time]);

  const handleJoin = (auctionId: string) => {
    socket.emit('auction_join', {
      auctionId
    });
  }

  useEffect(() => {
    const auctionId = searchParams.get('auction');
    if (auctionId) { handleJoin(auctionId) } else {
      navigate(ROUTES.HOME.URL);
    }

    socket.on('auction_join_success', (res) => {
      console.info('auction_join_success', res.auctionId);
      setTime(Math.round(res.ttlMilliseconds / 1000))
    });

    socket.on('auth_failed', (message) => {
      console.info('auth_failed', message);
      navigate(ROUTES.HOME.URL)
    });

    socket.on('auction_join_failed', (message) => {
      console.info('auction_join_failed', message);
      navigate(ROUTES.HOME.URL);
    });

    socket.on('lot_started', (res: IAuctionLot) => {
      setLot(() => {
        return res;
      });
      setTime(Math.round(res.ttlMilliseconds / 1000))
    });

    socket.on('lot_in_progress', (res: IAuctionLot) => {
      setLot(() => {
        return res;
      });
      setTime(Math.round(res.ttlMilliseconds / 1000))
    });

    socket.on('lot_got_bet', (res: IAuctionLot) => {
      setLot(() => {
        return res;
      });
      setTime(Math.round(res.ttlMilliseconds / 1000))
    })

    socket.on('lot_finished', (res: LotFinished) => {
      setTime(0)
      setIsUserWinner(userId === res.userId)
      if (isUserWinner) { message.success('Вы победили!') }
    })

    socket.on('auction_finished', () => {
      setTime(0)
      setLot(undefined)
      handleGoBack()
    })
  }, [])

  return (
    <Wrapper>
      <Flex className={styles.container} justify='flex-start'>
        <Flex className={styles.backContainer} onClick={handleGoBack} justify='center' align='center'>
          <LeftOutlined />
          <Typography className={styles.back}>Назад</Typography>
        </Flex>
      </Flex>
      {!lot && time
        ? <Typography className={styles.timer}>
        До начала аукциона {formatTime(time)}
      </Typography>
        : <></>}
      <Flex vertical gap='middle' align='center' justify='center'>
        {lot
          ? <AuctionCard
              key={lot.id}
              imageSrc={lot.pathToCover}
              gallery={lot.name}
              artistFullName={lot.artistFullName}
              cardType='lot'
              bet={!lot.currentBet.prevUserId ? `Старт: ${lot.currentBet?.amount} ₽` : `Текущая цена лота: ${lot.currentBet?.prevAmount ? lot.currentBet?.prevAmount : lot.currentBet?.amount} ₽`}
              materials={lot.techniqueAndMaterials}
              type={'active'}
              cardAction={ lot.currentBet.prevUserId !== userId
                ? <Button disabled={lot.currentBet.prevUserId === userId} className={styles.button} type='primary' onClick={() => {
                  handleButtonPress(lot.currentBet.id);
                }}>Сделать ставку {lot.currentBet.amount}₽</Button>
                : <></>}
              isLeading={lot.currentBet.prevUserId === userId}>
                <Typography className={styles.timer}>
                  {formatTime(time)}
                </Typography>
            </AuctionCard>
          : <></>}
      </Flex>
    </Wrapper>
  )
}
