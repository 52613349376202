import { type FC, type ReactNode } from 'react';
import { Link } from 'react-router-dom';

import styles from './styles.module.scss';

interface MenuButtonProps {
  children: ReactNode
  isActive?: boolean
  href: string
}

export const MenuButton: FC<MenuButtonProps> = ({ children, isActive, href }) => {
  return (
    <Link to={href}>
      <button className={isActive ? styles.activeButton : styles.button}>{children}</button>
    </Link>
  );
};
