import { message } from 'antd';
import React, {
  type ChangeEvent,
  type RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { REACT_APP_API_URL } from '../../../../common/constans/apiRoutes';
import { lotService } from '../../../../common/services/lots';
import { type ILot } from 'src/common/types';
import { type RouteParams } from '../../../../types/route';
import { type LotsToUpdate, useAuctionsStore } from '../../store/useAuctionsStore';

export const useEditLot = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [imagePaths, setImagePaths] = useState<string[]>([]);
  const [inputsData, setInputsData] = useState<LotsToUpdate[]>([]);
  const itemRefs = useRef<Array<React.RefObject<HTMLInputElement>>>([]);
  const { id } = useParams<RouteParams<'EDIT_AUCTION'>>();

  const { updateLotsInfo, setLotsToUpdate } = useAuctionsStore((state) => state);
  const { lots, deleteLotById } = useAuctionsStore((state) => state);

  useEffect(() => {
    setIsLoading(true);

    if (id) {
      lotService
        .getAdminLotsByAuctionId(id)
        .then((res) => {
          if (res) { updateLotsInfo(res); }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [id, updateLotsInfo]);

  useEffect(() => {
    setImagePaths(lots.map((lot) => `${REACT_APP_API_URL}/${lot.pathToCover}`));
  }, [lots]);

  useEffect(() => {
    if (lots && lots.length > 0) {
      itemRefs.current = lots.map(() => React.createRef<HTMLInputElement>());
    }
  }, [isLoading, lots]);

  useEffect(() => {
    setLotsToUpdate(inputsData);
  }, [inputsData, setLotsToUpdate]);

  const handleImageChange = useCallback(
    (uploadRef: RefObject<HTMLInputElement>, id: string, index: number) => () => {
      const uploadFile = uploadRef.current?.files?.[0];

      if (!uploadFile) {
        message.error('File not exist');
        return;
      }

      const cashedUrl = URL.createObjectURL(uploadFile);

      if (uploadFile) {
        lotService
          .editImage(id, uploadFile)
          .then(() => {
            setImagePaths((prevImagePaths) => {
              const newImagePaths = [...prevImagePaths];
              newImagePaths[index] = cashedUrl;
              return newImagePaths;
            });
          })
          .catch((e) => {
            return message.error(e);
          });
      }
    },
    [],
  );

  const handleInputChange = useCallback(
    (lotIndex: string, key: keyof ILot) => (event: ChangeEvent<HTMLInputElement>) => {
      setInputsData((prevInputsData) => {
        const updatedInputData = prevInputsData.map((inputData) => {
          if (inputData.id === lotIndex) {
            return {
              ...inputData,
              [key]: event.target.value,
            };
          }
          return inputData;
        });

        if (updatedInputData.every((inputData) => inputData.id !== lotIndex)) {
          const lot = lots.find((el) => el.id === lotIndex);
          if (lot) {
            updatedInputData.push({
              id: lotIndex,
              name: lot.name,
              size: lot.size,
              artistFullName: lot.artistFullName,
              techniqueAndMaterials: lot.techniqueAndMaterials,
              index: lot.index,
              [key]: event.target.value,
            });
          }
        }

        return updatedInputData;
      });
    },
    [lots],
  );

  const handleDeleteLot = useCallback(
    (lotId: string) => () => {
      lotService.deleteLot(lotId).then(() => {
        deleteLotById(lotId);
      });
    },
    [deleteLotById],
  );

  return { handleImageChange, isLoading, imagePaths, itemRefs, handleInputChange, handleDeleteLot };
};
