import SearchOutlined from '@ant-design/icons/lib/icons/SearchOutlined'
import Button from 'antd/es/button'
import Flex from 'antd/es/flex'
import Input from 'antd/es/input/Input'
import TextArea from 'antd/es/input/TextArea'
import Title from 'antd/es/typography/Title'
import { useMemo, useState } from 'react'
import { PageTitle } from 'src/common/components/PageTitle'
import UserChat from 'src/common/components/UserChat'
import { type IMessage, messagesService, type IMessageItem } from 'src/common/services/messages'

export default function Messages () {
  const [messages, setMessages] = useState<IMessage[]>();
  const [textareaValue, setTextareaValue] = useState<string>('');

  useMemo(() => {
    const getMessages = async () => {
      const res = await messagesService.getMessages();
      setMessages(res);
    }
    getMessages();
  }, [])

  const handleSearch = () => {}

  const handleAnswer = async (answer: string, userId: string, chatId: string) => {
    const res: IMessageItem | null = await messagesService.postOutgoing(answer, userId);
    if (!res || !messages) return;
    const messagesOwn = [...messages];
    const chat = messagesOwn?.find((message) => message.id === chatId)
    chat?.messages.push(res)

    setMessages(messagesOwn);
  }

  const answerToAll = async (message: string) => {
    await messagesService.postBroadcast(message);
  }

  return (
    <>
      <Flex align='center' justify='space-between' style={{
        paddingBottom: 24
      }}>
        <PageTitle title="Сообщения" />
        <Input addonBefore={<SearchOutlined />} placeholder="Найти" style={{ width: 200 }} onChange={handleSearch} />
      </Flex>
      <Flex align='flex-start' justify='space-between' style={{
        width: '100%'
      }}>
        <Flex vertical align='flex-start' justify='flex-start' style={{
          width: '100%'
        }}>
          <Title level={4}>Сообщение для всех пользователей</Title>
          <TextArea value={textareaValue} onChange={(event) => {
            event.preventDefault();
            setTextareaValue(event.target.value);
          }} rows={4} placeholder="Введите текст сообщения" maxLength={300} style={{
            maxWidth: '504px',
            width: '100%',
          }}/>
          <Button type='primary' style={{
            marginTop: '12px',
            height: '40px',
            width: '153px'
          }} onClick={() => { answerToAll(textareaValue); setTextareaValue('') }}>Отправить всем</Button>
        </Flex>
        <Flex vertical style={{
          width: '100%',
          maxWidth: '50%'
        }}>
          <Title level={4}>Чаты с пользователями</Title>
          <Flex vertical gap={12}>
            {messages?.map((chat: IMessage, index) => {
              return <UserChat chat={chat} index={index} key={chat.id} handleAnswer={handleAnswer} />
            })}
          </Flex>
        </Flex>
      </Flex>
    </>
  )
}
