import { Flex, Input, Typography } from 'antd';
import { type InputProps } from 'antd/es/input/Input';
import React, { type FC } from 'react';

import styles from './styles.module.scss';

interface DefaultInputProps extends InputProps {
  label: string
  validationMessage?: string
}

export const DefaultInput: FC<DefaultInputProps> = ({ label, validationMessage, ...rest }) => {
  return (
    <Flex vertical className={styles.container}>
      <Typography className={styles.label}>{label}</Typography>
      <Input {...rest} />
      <Typography className={styles.validation}>{validationMessage}</Typography>
    </Flex>
  );
};
