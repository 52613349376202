import styles from './AuctionResults.module.scss'
import Button from 'antd/es/button/button'
import Flex from 'antd/es/flex'
import { PageTitle } from 'src/common/components/PageTitle'
import DownloadSvg from '../../../assets/icons/Download.svg'
import Title from 'antd/es/typography/Title'
import Card from 'antd/es/card/Card'
import Typography from 'antd/es/typography'
import { useNavigate, useParams } from 'react-router-dom'
import { type RouteParams } from 'src/types/route'
import { useAuctionsStore } from '../store/useAuctionsStore'
import { REACT_APP_API_URL } from 'src/common/constans/apiRoutes'
import { Image } from 'antd'
import { useEditAuction } from '../EditAuction/hooks/useEditAuction'
import { type IAdminLot } from 'src/common/types'
const { Text } = Typography;

export default function AuctionResults () {
  const navigate = useNavigate();
  const auctions = useAuctionsStore((state) => state.auctions);
  const { handleGoBack } = useEditAuction();
  const { id } = useParams<RouteParams<'METRIC'>>();

  const auction = auctions.find((auction) => {
    return auction.id === id;
  })

  console.log(auction);

  const date = auction?.datetime ? new Date(auction.datetime).toLocaleDateString() : '';
  const time = auction?.datetime ? new Date(auction.datetime).toLocaleTimeString() : '';

  const fields = [{
    key: 'Посетило',
    value: auction?.metric?.visitorsTotal,
  },
  {
    key: 'Новых',
    value: auction?.metric?.visitorsNew,
  },
  {
    key: 'На пике',
    value: auction?.metric?.visitorsPeak,
  },
  {
    key: 'В конце',
    value: auction?.metric?.visitorsEnd,
  },
  {
    key: 'Самые большие ставки',
    value: auction?.metric?.highestBet,
  },
  {
    key: 'Самые маленькие ставки',
    value: auction?.metric?.smallestBet,
  },
  {
    key: 'Средний чек',
    value: auction?.metric?.averageArtPrice,
  },
  {
    key: 'Количество ставок',
    value: auction?.metric?.betsCount,
  },
  {
    key: 'Количество уникальных пользователей, сделавших ставки',
    value: auction?.metric?.betUsersCount,
  }];

  return (
    <>
      <Flex align='center' justify='space-between' style={{
        paddingBottom: 24
      }}>
        <PageTitle withBackArrow onClickArrow={handleGoBack} title="Результаты аукциона" />
        <Button style={{
          display: 'flex',
          alignItems: 'center',
          gap: '10px'
        }}><img src={DownloadSvg} alt='Download' /><span>Скачать результаты</span></Button>
      </Flex>
      <Flex justify='space-between'>
        <Flex vertical>
          <Flex gap={16} style={{
            marginBottom: '24px'
          }}>
            <Flex align='start' justify='start'>
              <Image style={{
                borderRadius: 6,
                objectFit: 'contain'
              }} src={REACT_APP_API_URL + '/' + auction?.pathToCover} preview={{
                mask: false
              }} width={110} height={110} alt='Gallery Art it Easy' />
            </Flex>
            <Flex vertical align='start' justify='start'>
              <Title level={5} style={{
                margin: 0,
                fontWeight: 'normal'
              }}>
                {auction?.name}
              </Title>
              <Typography>{`${date} | ${time}`}</Typography>
            </Flex>
          </Flex>
          <Card style={{
            padding: 16,
            maxWidth: 504,
            width: '100%'
          }}>
            <Flex vertical gap={16}>
            {fields.map((field) => {
              return (
                  <Flex align='center' justify='space-between' key={field.key + field.value}>
                    <Text style={{
                      maxWidth: 386
                    }}>{field.key}</Text>
                    <Text style={{
                      fontWeight: 'bold'
                    }}>{field.value}</Text>
                  </Flex>
              );
            })}
            </Flex>
          </Card>
        </Flex>
        <Flex vertical style={{
          width: '100%',
          maxWidth: '60%'
        }}>
          {auction?.lots?.map((lot: IAdminLot, index) => {
            console.log(lot.pathToCover)
            return (
              <Card key={lot.id} style={{ width: '100%', marginTop: 10 }} >
                <Flex gap={16} justify='space-between' style={{ width: '100%' }}>
                  <Flex vertical gap={8} style={{ padding: 16 }}>
                    <Flex align='center' gap={8} justify='flex-start'>
                      <Title style={{ margin: 0 }} level={5}>Лот №{index + 1} </Title>
                      {lot.metric?.soldPrice
                        ? <p className={styles.sold}>Продан</p>
                        : <p className={styles.notSold}>Не продан</p>
                    }
                    </Flex>
                    <Flex gap={16}>
                    <img style={{
                      borderRadius: 6
                    }} width={120} height={120} src={REACT_APP_API_URL + '/' + lot.pathToCover} alt={lot.name}/>
                    <Flex vertical>
                      {lot.metric?.soldPrice && <Text style={{
                        fontWeight: 600,
                        fontSize: 20
                      }}>{lot.metric?.soldPrice} ₽</Text>}
                      <Text style={{
                        fontWeight: 600
                      }}>{lot.name}. {lot.artistFullName}</Text>
                      <Text>Размер: {lot.size}</Text>
                      {lot.metric?.user && <>
                        <Text style={{
                          color: '#949494'
                        }}>{lot.metric.user.tgUsername} +{lot.metric?.user.phone}</Text>
                                              <Button style={{
                                                width: 190,
                                                marginTop: 12,
                                                background: '#F7F7F7',
                                                borderRadius: 9,
                                                border: 'none'
                                              }} onClick={() => { navigate('/admin/messages'); }}>Написать сообщение</Button>
                      </> }
                    </Flex>
                  </Flex>
                  </Flex>
                  <Flex vertical justify='flex-start' style={{
                    background: '#FAFAFA',
                    padding: 16
                  }} align='center'>
                    <Flex vertical justify='space-between' style={{
                      width: '100%'
                    }}>
                      <Title style={{
                        margin: 0
                      }} level={2}>{lot.metric?.betsCount}</Title> <Text>Всего ставок</Text>
                    </Flex>
                    <div className={styles.separator} />
                    <Flex vertical justify='space-between' style={{
                      width: '100%'
                    }}>
                      <Title style={{
                        margin: 0
                      }} level={2}>{lot.metric?.betUsersCount}</Title> <Text>Пользователей, сделавших ставки</Text>
                    </Flex>
                  </Flex>
                </Flex>
              </Card>
            );
          })}
        </Flex>
      </Flex>
    </>
  )
}
