import { Flex, Typography } from 'antd';
import { type FC } from 'react';

import { REACT_APP_API_URL } from '../../constans/apiRoutes';
import { ImagePreview } from '../ImagePreview';

import styles from './styles.module.scss';

interface LotPreviewProps {
  src: string
  artistFullName: string
  name: string
  size: string
  techniqueAndMaterials: string
  lotNumber: string
}

export const LotPreview: FC<LotPreviewProps> = ({
  src,
  artistFullName,
  name,
  size,
  techniqueAndMaterials,
  lotNumber,
}) => {
  return (
    <Flex className={styles.container} vertical gap={5}>
      <Typography className={styles.name}>{lotNumber}</Typography>
      <Flex gap={10}>
        <ImagePreview src={`${REACT_APP_API_URL}/${src}`} className={styles.imagePreview} />
        <Flex vertical>
          <Typography className={styles.name}>{name}</Typography>
          <Typography className={styles.name}>{artistFullName}</Typography>
          <Typography className={styles.description}>Размер: {size}</Typography>
          <Typography className={styles.description}>{techniqueAndMaterials}</Typography>
        </Flex>
      </Flex>
    </Flex>
  );
};
