import { create } from 'zustand';
import { type GetaAuctionByIdResponse } from '../../../common/services/auctions/interfaces/response';
import { type IAdminAuction, type ILot } from 'src/common/types';

export type LotsToUpdate = Pick<
ILot,
'name' | 'artistFullName' | 'size' | 'techniqueAndMaterials' | 'id' | 'index'
>;

export interface CreateLots extends ILot {
  file: File | string
  auctionId: string
}

interface AuctionGeneralInfo {
  name: string
  datetime: string
  file: File
}

interface State {
  auction: IAdminAuction | null
  auctionGeneralInfo?: AuctionGeneralInfo
  lots: ILot[] | []
  createLots: CreateLots[] | []
  auctions: IAdminAuction[] | []
  updateLots: LotsToUpdate[]
  shouldUpdateGeneralInfo: boolean
  searchQuery: string
}

interface UpdateAuctionInfo extends GetaAuctionByIdResponse {
  file?: File | string
}

interface Action {
  updateAuctionInfo: (auction: IAdminAuction | null) => void
  updateLotsInfo: (lots: ILot[]) => void
  deleteLotById: (id: string) => void
  setAuctions: (newAuctions: IAdminAuction[]) => void
  updateAuctionField: (field: keyof UpdateAuctionInfo, value?: File | string) => void
  setLotsToUpdate: (newLots: LotsToUpdate[]) => void
  setLotsToCreate: (lots: CreateLots[]) => void
  deleteLotToCreate: (id: string) => void
  setAuctionGeneralInfo: (auctionGeneralInfo: State['auctionGeneralInfo']) => void
  setSearchQuery: (query: string) => void
}

export const useAuctionsStore = create<State & Action>((set) => ({
  auctions: [],
  auction: null,
  auctionGeneralInfo: undefined,
  lots: [],
  updateLots: [],
  createLots: [],
  shouldUpdateGeneralInfo: false,
  searchQuery: '',
  updateAuctionInfo: (auction) => {
    set(() => ({ auction }));
  },
  updateLotsInfo: (lots) => {
    set(() => ({ lots }));
  },
  deleteLotById: (id) => {
    set((state) => ({ lots: state.lots.filter((lot) => lot.id !== id) }));
  },
  setAuctions: (newAuctions) => {
    set(() => ({ auctions: newAuctions }));
  },
  setAuctionGeneralInfo: (auctionGeneralInfo) => {
    set(() => ({ auctionGeneralInfo }));
  },
  updateAuctionField: (field, value) => {
    set((state) => ({
      auction: state.auction != null ? { ...state.auction, [field]: value } : null,
      shouldUpdateGeneralInfo: true,
    }));
  },
  setLotsToUpdate: (newLots) => {
    set(() => ({ updateLots: newLots }));
  },
  setLotsToCreate: (newLots) => {
    set(() => ({ createLots: newLots }));
  },
  deleteLotToCreate: (id) => {
    set((state) => ({ createLots: state.createLots.filter((lot) => lot.id !== id) }));
  },
  setSearchQuery: (query) => {
    set(() => ({ searchQuery: query }));
  },
}));
