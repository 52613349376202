export const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

export enum ApiRoute {
  AUTH = '/auth',
  AUCTIONS = '/auctions',
  LOTS = '/lots',
  USERS = '/users',
  MESSAGES = '/messages',
  ADMIN_LOGIN = '/auth/admin-login',
  REMINDERS = '/reminders',
  NOTIFICATIONS = '/notifications',
  USER_AUCTIONS = '/user-auctions',
  USER_LOTS = '/user-lots',
}
