import UserOutlined from '@ant-design/icons/lib/icons/UserOutlined'
import Avatar from 'antd/es/avatar/avatar'
import Card from 'antd/es/card/Card'
import Flex from 'antd/es/flex'
import { useState } from 'react'
import { Typography } from 'antd';
import Input from 'antd/es/input/Input'
import Button from 'antd/es/button'
import { type IMessage } from 'src/common/services/messages'
import styles from './UserChat.module.scss'
const { Text } = Typography;

export default function UserChat ({ chat, handleAnswer }: { chat: IMessage, index: number, handleAnswer: (answer: string, userId: string, chatId: string) => void }) {
  const [answer, setAnswer] = useState<string>('');
  return (
    <Card className={styles.body} bodyStyle={{ width: '100%' }} style={{ width: '100%', padding: 16, display: 'flex', justifyContent: 'space-between' }} >
      {chat.messages.sort((a, b) => {
        return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
      }).map((item) => {
        return (
            <Flex key={item.id} align={'center'} justify={item.direction === 1 ? 'end' : 'flex-start'} gap={16} style={{
              marginBottom: '24px',
              width: '100%',
              flexDirection: item.direction === 1 ? 'row-reverse' : 'row',
            }}>
              <Avatar icon={<UserOutlined />} />
              <Flex vertical align={item.direction === 1 ? 'flex-end' : 'flex-start'} justify='flex-start' gap={3}>
                <Text type="secondary">{item.direction === 1 ? 'Admin' : chat.tgUsername} {String(new Date(item.createdAt).toLocaleDateString())} {String(new Date(item.createdAt).toLocaleTimeString())}</Text>
                <Text>{item.text}</Text>
              </Flex>
            </Flex>
        )
      })}
      <Flex gap={12}>
        <Input style={{
          padding: 9
        }} value={answer} onChange={(event) => {
          event.preventDefault();
          setAnswer(event.target.value);
        }} placeholder="Введите текст сообщения" />
        <Button style={{
          height: 40
        }} onClick={() => {
          handleAnswer(answer, chat.messages[0].userId, chat.id);
          setAnswer('');
        }} type='primary'>Ответить</Button>
      </Flex>
    </Card>
  )
}
