import styles from './styles.module.scss'
import { Button, Flex, message } from 'antd';
import Wrapper from 'src/common/components/Wrapper';
import { useEffect, useState } from 'react';
import { type IBet, type IAuction } from 'src/common/types';
import { AuctionCard } from 'src/common/components/AuctionCard';
import { useParams, useNavigate } from 'react-router-dom';
import { type RouteParams } from 'src/types/route';
import { lotService } from 'src/common/services/lots';
import { auctionService } from 'src/common/services/auctions';
import { remindersService } from 'src/common/services/reminders';

export interface IFutureLot {
  id: string
  name: string
  size: string
  artistFullName: string
  fileKey: string
  auctionId: string
  createdAt: string
  updatedAt?: string
  deletedAt: string | null
  techniqueAndMaterials: string
  pathToCover: string
  currentBet?: IBet
  hasUserReminder: boolean
}

export default function Auction () {
  const { id } = useParams<RouteParams<'AUCTION'>>();
  const userId = localStorage.getItem('userId');
  const navigate = useNavigate();

  const [lots, setLots] = useState<IFutureLot[]>();
  const [auction, setAuction] = useState<IAuction>();

  const getLots = async () => {
    try {
      if (!id) {
        message.error('Аукцион не найден');
        navigate('/auctions');
        return;
      }
      const PUBLISHED_LOTS = await lotService.getUserLotsByAuctionId(id);

      if (PUBLISHED_LOTS) {
        getReminders(PUBLISHED_LOTS)
      } else {
        message.error('Аукцион не найден');
        navigate('/auctions');
      }
    } catch (error) {
      setLots([]);
        message.error('Аукцион не найден');
        navigate('/auctions');
        return;
    }
  };

  const getAuction = async () => {
    try {
      if (!id) {
        message.error('Аукцион не найден');
        navigate('/auctions');
        return;
      }

      const AUCTION = await auctionService.getUserAuctionByID(id);

      if(AUCTION && AUCTION.isFinished) {
        message.error('Аукцион закончен');
        navigate('/auctions');
        return;
      }

      if (AUCTION) {
        setAuction(AUCTION); 
      } else {
        message.error('Аукцион не найден');
        navigate('/auctions');
        return;
      }
    } catch (error) {
      setLots([]);
        message.error('Аукцион не найден');
        navigate('/auctions');
        return;
    }
  };

  useEffect(() => {
    getLots()
    getAuction()
  }, [])

  const getReminders = async (lots: IFutureLot[]) => {
    const USER_REMINDERS = await remindersService.getReminders();

    if (USER_REMINDERS) {
      const newLotsWithReminders = lots?.map((lot) => {
        const lotWithReminder = USER_REMINDERS.find((reminder) => {
          if (reminder.refType === 'LOT' && reminder.entity.id === lot.id) {
            return lot;
          }
          return false;
        })
        if (lotWithReminder) {
          return { ...lot, hasUserReminder: true };
        } else {
          return lot
        }
      })
      setLots(newLotsWithReminders)
    }
  };

  const handleButtonPress = async (lotId: string, amount: number, betId: string) => {
    const res: IBet = await lotService.makeBet(lotId, amount, betId);
    const updatedLots = lots?.map((lot) => {
      if (lot.id === res.lotId) {
        return { ...lot, currentBet: res }
      }
      return lot;
    })
    setLots(updatedLots);
  }

  const handleRemind = async (lotId: string) => {
    const res = await remindersService.lotStart(lotId);

    if (!res) {
      message.success('Напоминание о Старте Лота успешно создано!')
      const lotsWithReminder = lots?.map((lot) => {
        if (lot.id === lotId) {
          return { ...lot, hasUserReminder: true }
        }
        return lot;
      })
      setLots(lotsWithReminder)
    } else {
      message.error(res)
    }
  }

  return (
    <Wrapper title={auction?.name ?? ''}>
    <Flex style={{ width: '100%' }} align='center' justify='flex-start' vertical>
      <Flex vertical gap="middle" align='center' justify='center' style={{ marginTop: '0px' }}>
        {lots?.map((lot) => {
          return (
            <AuctionCard
            key={lot.id}
            imageSrc={lot.pathToCover}
            gallery={lot.name}
            artistFullName={lot.artistFullName}
            cardType='lot'
            bet={!lot.currentBet?.prevUserId ? `Старт: ${lot.currentBet?.amount} ₽` : `Текущая цена лота: ${lot.currentBet?.prevAmount ? lot.currentBet?.prevAmount : lot.currentBet?.amount} ₽`} materials={lot.techniqueAndMaterials}
            size={lot.size}
            type={'active'}
            cardAction={
            <>
            <Button disabled={lot.currentBet && lot.currentBet.prevUserId === userId} className={styles.button} type='primary' onClick={() => {
              if (lot?.currentBet) { handleButtonPress(lot.id, lot?.currentBet?.amount, lot?.currentBet.id); }
            }}>Сделать заочную ставку {lot?.currentBet?.amount}₽</Button>
            <Button style={{
              marginTop: 10
            }} disabled={lot.hasUserReminder} onClick={async () => {
              await handleRemind(lot.id);
            }}>Напомнить</Button>
            </>
        }
            isLeading={lot.currentBet && lot.currentBet.prevUserId === userId}>
          </AuctionCard>
          )
        })}
      </Flex>
    </Flex>
    </Wrapper>
  );
}
