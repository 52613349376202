import { CloudUploadOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Flex, Typography } from 'antd';
import { type FC } from 'react';

import styles from './styles.module.scss';

interface UnpublishedCardButtonsProps {
  onUpload?: () => void
  onEdit?: () => void
  onDelete?: () => void
}

export const UnpublishedCardButtons: FC<UnpublishedCardButtonsProps> = ({
  onUpload,
  onEdit,
  onDelete,
}) => {
  return (
    <Flex gap={4}>
      <button className={styles.button} onClick={onUpload}>
        <CloudUploadOutlined />
        <Typography>Опубликовать</Typography>
      </button>
      <button className={styles.button}>
        <EditOutlined onClick={onEdit} />
      </button>
      <button className={styles.button}>
        <DeleteOutlined onClick={onDelete} />
      </button>
    </Flex>
  );
};
