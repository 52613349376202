import { Tabs } from 'antd';
import { TabsData } from './TabsData';
import { AuctionsHeader } from './components/AuctionsHeader';

export const Admin = () => {
  return (
    <>
      <AuctionsHeader />
      <Tabs destroyInactiveTabPane defaultActiveKey="0" items={TabsData} />
    </>
  );
};
