import { type FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { ROUTES } from './routeNames';
import { useToken } from '../common/hooks/useToken';

export const PrivateRoute: FC = () => {
  const { adminToken } = useToken()

  if (adminToken === null || adminToken === '') {
    return <Navigate to={ROUTES.LOGIN_PAGE.URL} />;
  }

  return <Outlet />;
};
