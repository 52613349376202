import { callApi } from '../../../api/callApi';
import { ApiRoute } from '../../constans/apiRoutes';

interface IUserResponse {
  count: number
  users: IUser[]
}

export interface IUser {
  id: string
  phone: string
  tgUserId: string
  tgUsername: string
}

export const userService = {
  getUsers: async (take: 'all' | 50 | 20 | 10 = 'all', skip: number = 0, query?: string): Promise<IUserResponse> => {
    try {
      const res: IUserResponse = await callApi({
        route: `${ApiRoute.USERS}/search?take=${take}&skip=${skip}`,
      })
      return res;
    } catch (error) {
      console.error('Reminder Service: getReminders', error);
      return {
        count: 0,
        users: []
      };
    }
  },
}
