import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Flex, Typography } from 'antd';
import { type FC } from 'react';

import styles from './styles.module.scss';

interface CardActionButtonsProps {
  onEditPress?: () => void
  onDeletePress?: () => void
  onResultsPress?: () => void
  isPassed?: boolean
}

export const CardActionButtons: FC<CardActionButtonsProps> = ({
  onEditPress,
  onDeletePress,
  onResultsPress,
  isPassed,
}) => {
  if (isPassed) {
    return (
      <button className={`${styles.button} ${styles.full}`} onClick={() => {
        if (onResultsPress) {
          onResultsPress()
        }
      }}>
        <Typography>Смотреть результаты</Typography>
      </button>
    );
  }

  return (
    <Flex gap={8}>
      <button className={styles.button} onClick={onEditPress}>
        <EditOutlined />
      </button>
      <button className={styles.button} onClick={onDeletePress}>
        <DeleteOutlined />
      </button>
    </Flex>
  );
};
