import { type ThemeConfig } from 'antd';
import './index.scss';

export const theme: ThemeConfig = {
  components: {
    Button: {
      colorPrimaryActive: '#252525',
      primaryShadow: '#333333',
      colorPrimaryHover: '#444444',
    },
    Tabs: {
      inkBarColor: 'black',
      itemActiveColor: 'black',
      itemHoverColor: 'black',
      itemSelectedColor: 'black',
      colorBorderSecondary: 'transparent',
    },
    Card: {
      paddingLG: 0,
    },
    Layout: {
      headerBg: '#fff',
      bodyBg: '#fff'
    },
  },
};
