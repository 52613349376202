import { CloseOutlined } from '@ant-design/icons';
import { Flex, Typography } from 'antd';
import { createRef, useRef } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { AddMoreButton } from '../../../../common/components/AddMoreButton';
import { ImageUpload } from '../../../../common/components/ImageUpload';
import { LotInputFields } from '../../components/LotInputFields';
import { useAuctionsStore } from '../../store/useAuctionsStore';
import { useCreateLot } from '../hooks/useCreateLot';
import { useEditLot } from '../hooks/useEditLot';

import styles from './styles.module.scss';

export const EditLots = () => {
  const nodeRefs = useRef([]);

  const { lots, auction } = useAuctionsStore((state) => state);
  const { handleImageChange, imagePaths, itemRefs, handleInputChange, handleDeleteLot } =
    useEditLot();

  const {
    lotsToCreate,
    addNewLot,
    handleInput,
    itemRefs: createItemRefs,
    imagePaths: createImagePaths,
    handleImageChange: handleCreateImageChange,
    handleDeleteLot: handleDeleteLotToCreate,
  } = useCreateLot();

  return (
    <Flex vertical gap={20}>
      <TransitionGroup className={styles.lotsContainer}>
        {lots?.map((lot, id) => {
          nodeRefs.current[id] = nodeRefs.current[id] || createRef();
          return (
            <CSSTransition
              key={lot.id}
              timeout={300}
              nodeRef={nodeRefs.current[id]}
              classNames={styles}
            >
              <div>
                <Flex justify="space-between">
                  <Typography className={styles.lotTitle}>{`Лот №${id + 1}`}</Typography>
                  <CloseOutlined onClick={handleDeleteLot(lot.id)} />
                </Flex>

                <Flex key={lot.id} vertical gap={32}>
                  <Flex gap={16} className={styles.container}>
                    <div>
                      <ImageUpload
                        onChange={handleImageChange(itemRefs.current[id], lot.id, id)}
                        shouldRenderPreview={!!imagePaths[id]}
                        imagePreview={imagePaths[id]}
                        name="file"
                        ref={itemRefs.current[id]}
                        className={styles.uploadContainer}
                      />
                    </div>
                    <LotInputFields lot={lot} handleInputChange={handleInputChange} />
                  </Flex>
                </Flex>
              </div>
            </CSSTransition>
          );
        })}
        {lotsToCreate.map((el, id) => {
          return (
            <CSSTransition key={el.id} timeout={300} classNames={styles}>
              <div>
                <Flex justify="space-between">
                  <Typography className={styles.lotTitle}>
                    {`Лот №${lots.length + (id + 1)}`}{' '}
                  </Typography>
                  <CloseOutlined onClick={handleDeleteLotToCreate(el.id)} />
                </Flex>
                <Flex gap={16} className={styles.container}>
                  <div>
                    <ImageUpload
                      shouldRenderPreview={!!createImagePaths[id]}
                      name="file"
                      imagePreview={createImagePaths[id]}
                      ref={createItemRefs.current[id]}
                      className={styles.uploadContainer}
                      onChange={handleCreateImageChange(createItemRefs.current[id], id)}
                    />
                  </div>
                  <LotInputFields lot={el} handleInputChange={handleInput} />
                </Flex>
              </div>
            </CSSTransition>
          );
        })}
      </TransitionGroup>
      {/* TODO: Изменить лоты если аукцион опубликован */}
      {!auction?.publishedAt && <AddMoreButton onClick={addNewLot} buttonText="Добавить лот" />}
    </Flex>
  );
};
