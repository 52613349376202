import { message } from 'antd';
import dayjs from 'dayjs';
import {
  type ChangeEvent,
  type RefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { REACT_APP_API_URL } from '../../../../common/constans/apiRoutes';
import { useDateTime } from '../../../../common/hooks/useDateTime';
import { auctionService } from '../../../../common/services/auctions';
import { type GetaAuctionByIdResponse } from '../../../../common/services/auctions/interfaces/response';
import { type RouteParams } from '../../../../types/route';
import { useAuctionsStore } from '../../store/useAuctionsStore';
import { useToken } from 'src/common/hooks/useToken';
import { type IAdminAuction } from 'src/common/types';

export const useEditGeneralInfo = () => {
  const { auction, updateAuctionInfo, updateAuctionField } = useAuctionsStore((state) => state);
  const [parseDateTime, , updateDateTime] = useDateTime();
  const { adminToken } = useToken()

  const [time, setTime] = useState<string | string[]>('');
  const [date, setDate] = useState<string | string[]>('');
  const [imagePreview, setImagePreview] = useState('');
  const [imageFile, setImageFile] = useState<File | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  // const initialValues = useMemo(() => {
  //   if (!isLoading && auction) {
  //     return {
  //       name: auction.name ?? '',
  //       date: dayjs(parseDateTime(auction.datetime, true).date),
  //       time: dayjs(parseDateTime(auction.datetime, true).time, 'HH:mm'),
  //       file: auction.pathToCover ?? '',
  //     };
  //   }

  //   return {};
  // }, [auction, isLoading, parseDateTime]);

  const initialValues = {
    name: auction ? auction.name : '',
    date: auction ? dayjs(parseDateTime(auction.datetime, true).date) : dayjs('0000-00-00'),
    time: auction ? dayjs(parseDateTime(auction.datetime, true).time, 'HH:mm') : dayjs('00:00'),
    file: auction ? auction.pathToCover : '',
  }

  const { id } = useParams<RouteParams<'EDIT_AUCTION'>>();

  const updateField = useCallback(
    (fieldName: keyof GetaAuctionByIdResponse) => (event: ChangeEvent<HTMLInputElement>) => {
      const newName = event.target.value;
      updateAuctionField(fieldName, newName);
    },
    [updateAuctionField],
  );

  const handleSelectDateTime = useCallback(
    (fieldName: 'date' | 'time') => (_date: dayjs.Dayjs, dateString: string | string[]) => {
      if (fieldName === 'date') {
        setDate(dateString);
      }

      setTime(dateString);
    },
    [time, date],
  );

  const handleImageChange = useCallback(
    (uploadRef: RefObject<HTMLInputElement>) => () => {
      const uploadFile = uploadRef.current?.files?.[0];

      if (!uploadFile) {
        message.error('File not exist');
        return;
      }

      const cashedUrl = URL.createObjectURL(uploadFile);

      try {
        setImageFile(uploadFile);
        setImagePreview(cashedUrl);
        updateAuctionField('file', uploadFile);
      } catch (e) {
        message?.error('ошибка при загрузке изображения');
      }
    },
    [],
  );

  useLayoutEffect(() => {
    if (id) {
      setIsLoading(true);
      auctionService
        .getAuctionByID(id, adminToken)
        .then((res: IAdminAuction | null) => {
          if (res) { updateAuctionInfo(res) }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [id, updateAuctionInfo]);

  useEffect(() => {
    setImagePreview(`${REACT_APP_API_URL}/${auction?.pathToCover}`);
  }, [auction?.pathToCover]);

  useEffect(() => {
    const updatedTime =
      auction && updateDateTime('time', auction?.datetime, Array.isArray(time) ? time[0] : time);

    if (updatedTime) {
      updateAuctionField('datetime', updatedTime);
    }
  }, [time, updateAuctionField]);

  useEffect(() => {
    const updatedDate =
      auction && updateDateTime('date', auction?.datetime, Array.isArray(date) ? date[0] : date);

    if (updatedDate) {
      updateAuctionField('datetime', updatedDate);
    }
  }, [date, updateAuctionField]);

  return {
    auction,
    initialValues,
    updateField,
    handleSelectDateTime,
    id,
    imagePreview,
    handleImageChange,
    imageFile,
    isLoading,
  };
};
