import { Link, useLocation } from 'react-router-dom';
import { ROUTES } from '../../../routes/routeNames';

import styles from './Navbar.module.scss';
import Coins from '../../../assets/icons/Coins.svg'
import CardsThree from '../../../assets/icons/CardsThree.svg'
import CalendarCheck from '../../../assets/icons/CalendarCheck.svg'
import Bell from '../../../assets/icons/Bell.svg'
import Reminders from '../../../assets/icons/Images.svg'

export default function Navbar () {
  const location = useLocation();

  const NAVBAR_ITEMS = [{
    title: 'Аукционы',
    url: ROUTES.AUCTIONS.URL,
    img: CardsThree,
  },
  {
    title: 'Мои ставки',
    url: ROUTES.MY_BETS.URL,
    img: Coins,
  },
  {
    title: 'Мои лоты',
    url: ROUTES.MY_LOTS.URL,
    img: Reminders,
  },
  {
    title: 'Уведомления',
    url: ROUTES.NOTIFICATIONS.URL,
    img: Bell,
  },
  {
    title: 'Напоминания',
    url: ROUTES.REMINDERS.URL,
    img: CalendarCheck,
  }];

  return (
    <div className={styles.wrapper}>
      {NAVBAR_ITEMS.map((item) => {
        return <Link key={item.title} to={item.url}>
        <div className={location.pathname === item.url ? styles.navBarItemActive : styles.navBarItem}>
          <img src={item.img} />
          <p className={styles.paragraph}>{item.title}</p>
        </div>
      </Link>
      })}
    </div>
  )
}
