import { useCallback } from 'react';

interface DateTime {
  formattedDate: string
  time: string
  date: string
}

type DateGap = 'future' | 'active' | undefined;

type DateTimeHook = [
  (datetimeString: string, isOldTime?: boolean) => DateTime,
  (datetimeString: string) => DateGap,
  (field: FieldType, value: string, currentDateTime: string) => string,
];

type FieldType = 'date' | 'time';

export const useDateTime = (): DateTimeHook => {
  const parseDateTime = useCallback((datetimeString: string, isOldTime: boolean = false): DateTime => {
    try {
      if (isOldTime) {
        const datetime = new Date(datetimeString);

        // Get the local date in YYYY-MM-DD format
        const date = datetime.toLocaleDateString('en-CA');

        // Get the local time in HH:MM format
        const time = datetime.toLocaleTimeString('en-GB', {
          hour12: false,
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit'
        });

        // Format the date to DD.MM.YYYY
        const formattedDate = date.split('-').reverse().join('.');

        return { formattedDate, time, date };
      }
      const datetime = new Date(datetimeString);

      const date = datetime ? new Date(datetime).toLocaleDateString() : '';
      const formattedDate = datetime ? new Date(datetime).toLocaleString() : '';
      const time = datetime ? new Date(datetime).toLocaleTimeString() : '';

      return { formattedDate, time, date };
    } catch (error) {
      console.error(error);
      return { formattedDate: '', time: '', date: '' };
    }
  }, []);

  const calculateAuctionLabel = useCallback((datetimeString: string): DateGap => {
    const datetime = new Date(datetimeString);
    const currentDatetime = new Date();

    // Учтем разницу во временных зонах
    const differenceMilliseconds =
      datetime.getTime() -
      currentDatetime.getTime() +
      currentDatetime.getTimezoneOffset() * 60 * 1000;

    const oneDayMilliseconds = 1000 * 60 * 60 * 24;
    // более суток
    if (differenceMilliseconds > oneDayMilliseconds) {
      return undefined;
    }

    // уже прошло
    if (differenceMilliseconds <= 0) {
      return 'active';
    }

    // менее суток
    return 'future';
  }, []);

  // const updateDateTime = (
  //   field: FieldType,
  //   value: string | string[],
  //   currentDateTime: string,
  // ): string => {
  //   const dateTime = new Date(currentDateTime);
  //
  //   if (field === 'date') {
  //     // Обновляем только дату
  //     const dateParts = (value as string).split('-');
  //     dateTime.setFullYear(parseInt(dateParts[0], 10));
  //     dateTime.setMonth(parseInt(dateParts[1], 10) - 1);
  //     dateTime.setDate(parseInt(dateParts[2], 10));
  //   } else if (field === 'time') {
  //     // Обновляем только время
  //     const timeParts = (value as string).split(':');
  //     dateTime.setHours(parseInt(timeParts[0], 10));
  //     dateTime.setMinutes(parseInt(timeParts[1], 10));
  //     // Убираем секунды и миллисекунды
  //     dateTime.setSeconds(0);
  //     dateTime.setMilliseconds(0);
  //   }
  //
  //   // Форматируем новую дату и время в формат, подходящий для сохранения в объекте
  //   return dateTime.toString();
  // };

  const updateDateTime = (
    type: 'date' | 'time',
    originalString: string,
    newValue: string | string[],
  ) => {
    let updatedString = originalString;

    if (type === 'date') {
      // Обновляем дату
      const parts = originalString.split('T');
      const timePart = parts[1];
      updatedString = `${newValue}T${timePart}`;
    } else if (type === 'time') {
      const parts = originalString.split('T');
      const datePart = parts[0];
      updatedString = `${datePart}T${newValue}.000Z`;
    }

    return updatedString;
  };

  return [parseDateTime, calculateAuctionLabel, updateDateTime];
};
