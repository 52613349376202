import { PageTitle } from 'src/common/components/PageTitle';
import { Flex, Space, Table } from 'antd';
import SearchOutlined from '@ant-design/icons/lib/icons/SearchOutlined';
import Input from 'antd/es/input/Input';
import Column from 'antd/es/table/Column';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'src/routes/routeNames';
import { useEffect, useState } from 'react';
import { type IUser, userService } from 'src/common/services/users';

export default function Members () {
  const navigate = useNavigate();
  const [users, setUsers] = useState<IUser[]>();

  const handleSearch = () => {}

  interface DataType {
    key: React.Key
    nickName: string
    phoneNumber: string
  }

  const getUsers = async () => {
    try {
      const res = await userService.getUsers();
      setUsers(res.users)
    } catch (error) {

    }
  }

  useEffect(() => {
    getUsers()
    return () => {
    }
  }, [])

  return (
    <>
      <Flex align='center' justify='space-between' style={{
        paddingBottom: 24
      }}>
        <PageTitle title="Участники" />
        <Input addonBefore={<SearchOutlined />} placeholder="Найти" style={{ width: 200 }} onChange={handleSearch} />
      </Flex>
      <Table dataSource={users}>
      <Column title="Telegram Никнейм" dataIndex="tgUsername" key="tgUsername" />
      <Column title="Telegram ID" dataIndex="tgUserId" key="tgUserId" width="50%" />
      <Column title="Номер телефона" dataIndex="phone" key={'phone'} width="100%" />
      <Column
        title="Действие"
        key="action"
        render={(_: any, record: DataType) => (
          <Space size="large">
            <a onClick={() => { navigate(ROUTES.MESSAGES.URL); }}>Написать</a>
          </Space>
        )}
      />
      </Table>
    </>
  )
}
