import { ArrowLeftOutlined } from '@ant-design/icons';
import { Space, Typography } from 'antd';
import { type FC } from 'react';

import styles from './styles.module.scss';

interface PageTitleProps {
  title: string
  withBackArrow?: boolean
  onClickArrow?: () => void
}

export const PageTitle: FC<PageTitleProps> = ({ title, withBackArrow, onClickArrow }) => {
  return (
    <Space align="center">
      {withBackArrow && <ArrowLeftOutlined className={styles.arrow} onClick={onClickArrow} />}
      <Typography.Title className={styles.title}>{title}</Typography.Title>
    </Space>
  );
};
