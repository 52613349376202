import { PlusOutlined } from '@ant-design/icons';
import { Flex, Typography } from 'antd';
import { type ButtonHTMLAttributes, type FC } from 'react';

import styles from './styles.module.scss';

interface AddMoreButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  buttonText: string
}

export const AddMoreButton: FC<AddMoreButtonProps> = ({ buttonText, ...rest }) => {
  return (
    <button className={styles.button} {...rest}>
      <Flex justify="center" align="center" gap={8}>
        <PlusOutlined className={styles.icon} />
        <Typography className={styles.buttonTitle}>{buttonText}</Typography>
      </Flex>
    </button>
  );
};
