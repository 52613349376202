import { SearchOutlined } from '@ant-design/icons';
import { Button, Flex, Input, Space } from 'antd';
import { type ChangeEvent, useCallback } from 'react';
import { Link } from 'react-router-dom';

import { PageTitle } from '../../../../common/components/PageTitle';
import { ROUTES } from '../../../../routes/routeNames';
import { useAuctionsStore } from '../../store/useAuctionsStore';

import styles from './styles.module.scss';

export const AuctionsHeader = () => {
  const [searchQuery, setSearchQuery] = useAuctionsStore((state) => [
    state.searchQuery,
    state.setSearchQuery,
  ]);

  const handleSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearchQuery(e.target.value);
    },
    [setSearchQuery],
  );

  return (
    <Flex justify="space-between" align="center">
      <PageTitle title="Аукционы" />
      <Space>
        <Input
          onChange={handleSearch}
          value={searchQuery}
          className={styles.input}
          prefix={<SearchOutlined className={styles.icon} />}
        />
        <Link to={ROUTES.CREATE_AUCTION.URL}>
          <Button type="primary">Cоздать аукцион</Button>
        </Link>
      </Space>
    </Flex>
  );
};
