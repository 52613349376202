import { useEffect, useState } from 'react';
import { generalService } from '../services/general';
import { ADMIN_LOGIN_DATE, ADMIN_TOKEN_KEY, USER_TOKEN_KEY } from '../constans/localStorageKeys';

const TOKEN_EXPIRY_INTERVAL = 3500000; // 58 minutes and 20 seconds

export const useToken = () => {
  const [adminToken, setAdminToken] = useState(localStorage.getItem(ADMIN_TOKEN_KEY) ?? '');
  const userToken = localStorage.getItem(USER_TOKEN_KEY) ?? '';
  const adminLoginData = Number(localStorage.getItem(ADMIN_LOGIN_DATE));
  const [isRefreshing, setIsRefreshing] = useState(false);

  useEffect(() => {
    const checkAndRefreshToken = async () => {
      const tokenExpired = adminLoginData && (Date.now() - adminLoginData) > TOKEN_EXPIRY_INTERVAL;

      if (tokenExpired && !isRefreshing) {
        setIsRefreshing(true);
        try {
          const refreshedTokens = await generalService.refresh(adminToken);
          if (refreshedTokens.access_token) {
            localStorage.setItem(ADMIN_TOKEN_KEY, refreshedTokens.access_token);
            setAdminToken(refreshedTokens.access_token);
            localStorage.setItem(ADMIN_LOGIN_DATE, String(Date.now())); // Update login date
          }
        } catch (error) {
          console.error('Error refreshing token:', error);
        } finally {
          setIsRefreshing(false);
        }
      }
    };

    checkAndRefreshToken();
  }, [adminToken, adminLoginData, isRefreshing]);

  return { adminToken, userToken };
};
