import Flex from 'antd/es/flex'
import Wrapper from 'src/common/components/Wrapper'
import { AuctionCard } from 'src/common/components/AuctionCard'
import { lotService } from 'src/common/services/lots'
import { useEffect, useState } from 'react'

export interface IMyBet {
  id: string,
  amount: number,
  lotId: string,
  userId: string,
  isBeforeStart: boolean,
  createdAt: string,
  deletedAt: null,
  lot: {
    id: string,
    index: number,
    name: string,
    size: string,
    artistFullName: string,
    techniqueAndMaterials: string,
    fileKey: string,
    auctionId: string,
    lotStartAmount: number,
    createdAt: string,
    updatedAt: string,
    deletedAt: null,
    pathToCover: string,
    auction: {
      id: string,
      name: string,
      datetime: string,
      fileKey: string,
      publishedAt: string,
      isCurrent: boolean,
      isFinished: boolean,
      createdAt: string,
      updatedAt: string,
      deletedAt: null
    }
  },
  isOverbid: boolean
}

export default function MyBets () {
  const [bets, setBets] = useState<IMyBet[]>([]);

  const getBets = async () => {
    const result = await lotService.getMyBets();
    if (result[0]) setBets(result);
  }

  useEffect(() => {
    getBets()
    return () => {
      setBets([])
    }
  }, [])

  return (
    <Wrapper title='Мои ставки'>
      <Flex vertical align="center">
        {bets?.length > 0 && bets?.map((bet) => {
          return (
            <AuctionCard
              key={bet.id}
              imageSrc={bet.lot.pathToCover}
              gallery={bet.lot.name}
              bet={bet.isOverbid ? `Ваша ставка перебита на ${bet.amount} ₽` : `Ваша ставка ${bet.amount} ₽`}
              isLeading={!bet.isOverbid}
              artistFullName={bet.lot.artistFullName}
              cardType='lot'
              materials={bet.lot.techniqueAndMaterials}
              size={bet.lot.size}
              type={'active'}
            />
          )
        })}
      </Flex>
    </Wrapper>
  )
}
