import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Button, Flex, Input, message } from 'antd';
import { useFormik } from 'formik';
import { type FormikHelpers } from 'formik/dist/types';
import { type ChangeEvent, useCallback } from 'react';

import { ReactComponent as Logo } from '../../../assets/icons/logo.svg';
import { generalService } from '../../../common/services/general';

import styles from './styles.module.scss';
import { useNavigate } from 'react-router-dom';
import { ADMIN_LOGIN_DATE, ADMIN_TOKEN_KEY } from '../../../common/constans/localStorageKeys';
import { ROUTES } from '../../../routes/routeNames';

interface LoginValues {
  login: string
  password: string
}

export const Login = () => {
  const navigate = useNavigate();
  const currentDate = new Date()

  const handleSubmit = useCallback(
    (values: LoginValues, formikHelpers: FormikHelpers<LoginValues>) => {
      formikHelpers.setSubmitting(true);

      generalService
        .adminLogin(values).then(res => {
          if (res.access_token) {
            localStorage.setItem(ADMIN_TOKEN_KEY, res.access_token);
            localStorage.setItem(ADMIN_LOGIN_DATE, currentDate.getDate().toString());
            navigate(ROUTES.ADMIN.URL);
          } else {
            formikHelpers.resetForm();
            message.error('Неверный логин или пароль')
          }
        }).finally(() => formikHelpers.setSubmitting(false))
      },
    [],
  );

  const formik = useFormik({
    initialValues: { login: '', password: '' },
    onSubmit: handleSubmit,
  });

  const handleChangeInput = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      formik.handleChange(e);
    },
    [formik],
  );

  return (
    <form onSubmit={formik.handleSubmit}>
      <Flex vertical className={styles.container} align="center" justify="center">
        <Logo className={styles.logo} />
        <Input
          placeholder="Логин"
          className={styles.input}
          prefix={<UserOutlined />}
          value={formik.values.login}
          onChange={handleChangeInput}
          name="login"
        />
        <Input.Password
          placeholder="Пароль"
          className={styles.input}
          prefix={<LockOutlined />}
          value={formik.values.password}
          onChange={handleChangeInput}
          name="password"
        />
        <Button
          loading={formik.isSubmitting}
          htmlType="submit"
          className={styles.button}
          type="primary"
        >
          Войти
        </Button>
      </Flex>
    </form>
  );
};
