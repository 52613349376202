import { LoadingOutlined, PlusOutlined, SyncOutlined } from '@ant-design/icons';
import { forwardRef, type MutableRefObject, useCallback } from 'react';

import styles from './styles.module.scss';

export interface ImageUploadProps {
  imagePreview?: string
  shouldRenderPreview?: boolean
  isLoading?: boolean
  onChange?: () => void
  className?: string
  name?: string
}

 
export const ImageUpload = forwardRef<HTMLInputElement, ImageUploadProps>(
  ({ shouldRenderPreview, imagePreview, isLoading, onChange, className, name }, ref) => {
    const handleClick = useCallback(() => {
      (ref as MutableRefObject<HTMLInputElement | null>).current?.click();
    }, [ref]);

    return (
      <>
        <div className={`${styles.imageContainer} ${className}`} onClick={handleClick}>
          {shouldRenderPreview
            ? (
            <>
              <img className={styles.avatar} src={imagePreview} alt="auction avatar" />
              <div className={styles.badge}>
                <SyncOutlined className={styles.sync} />
              </div>
            </>
              )
            : (
            <>{isLoading ? <LoadingOutlined /> : <PlusOutlined className={styles.plusIcon} />}</>
              )}
        </div>
        <input name={name} ref={ref} hidden type="file" onChange={onChange} />
      </>
    );
  },
);
