import { type IReminder } from 'src/types';
import { callApi } from '../../../api/callApi';
import { ApiRoute } from '../../constans/apiRoutes';
import { type AxiosError } from 'axios';

export const remindersService = {
  auctionStart: async (auctionId: string): Promise<string | null> => {
    try {
      await callApi({
        route: `${ApiRoute.REMINDERS}/${auctionId}/auction-start`,
        method: 'POST',
      })
      return null;
    } catch (error) {
      const AxiosError = error as AxiosError;

      if (AxiosError.message === 'User have active reminder') {
        return 'У пользователя есть активное напоминание';
      }
      console.error('Reminder Service: auctionStart', error);

      return 'Произошла ошибка в ходе создание напоминания'
    }
  },
  lotStart: async (lotId: string): Promise<string | null> => {
    try {
      await callApi({
        route: `${ApiRoute.REMINDERS}/${lotId}/lot-start`,
        method: 'POST',
      })
      return null;
    } catch (error) {
      const AxiosError = error as AxiosError;

      if (AxiosError.message === 'User have active reminder') {
        return 'У пользователя есть активное напоминание';
      }
      console.error('Reminder Service: auctionStart', error);

      return 'Произошла ошибка в ходе создание напоминания'
    }
  },
  getReminders: async (): Promise<IReminder[] | []> => {
    try {
      return await callApi({
        route: ApiRoute.REMINDERS,
      })
    } catch (error) {
      console.error('Reminder Service: getReminders', error);
      return [];
    }
  },
  postReadReminders: async (): Promise<IReminder[] | []> => {
    try {
      return await callApi({
        route: ApiRoute.REMINDERS + '/read',
        method: 'POST',
      })
    } catch (error) {
      console.error('Reminder Service: getReminders', error);
      return [];
    }
  }
}
