import Flex from 'antd/es/flex'
import Wrapper from 'src/common/components/Wrapper'
import { useEffect, useState } from 'react'
import { lotService } from 'src/common/services/lots'
import { type ILot } from 'src/common/types'
import { AuctionCard } from 'src/common/components/AuctionCard'

export interface IMyLot {
  id: string
  auctionId: string
  lotId: string
  userId: string
  createdAt: string
  lot: ILot
}

export default function MyLots () {
  const [lots, setLots] = useState<IMyLot[]>();

  const getLots = async () => {
    const result = await lotService.getMyLots();
    if (result) {
      setLots(result.sort((a, b) => {
        return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
      }));
    }
  }

  useEffect(() => {
    getLots()
    return () => {
      setLots([])
    }
  }, [])

  return (
    <Wrapper title='Мои лоты'>
      <Flex vertical align="center">
      {lots?.map((myLot) => {
        return (
            <AuctionCard
              key={myLot.id}
              imageSrc={myLot.lot.pathToCover}
              gallery={myLot.lot.name}
              artistFullName={myLot.lot.artistFullName}
              cardType='lot'
              bet={`Цена: ${myLot.lot.bet?.amount ?? ''} ₽`}
              materials={myLot.lot.techniqueAndMaterials}
              size={myLot.lot.size}
              type={'ended'}
            />
        )
      })}
      </Flex>
    </Wrapper>
  )
}
