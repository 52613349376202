import * as yup from 'yup';
import type { CreateLots } from '../../pages/Admin/store/useAuctionsStore';

export const useValidateLots = () => {
  const lotSchema = yup.object().shape({
    id: yup.string().required(),
    artistFullName: yup.string().required(),
    size: yup.string().required(),
    lotStartAmount: yup.number().min(1000).max(100000).required('Поле не может быть пустым'),
    techniqueAndMaterials: yup.string().required(),
    name: yup.string().required(),
  });

  const validateLots = async (lots: CreateLots[]) => {
    if (lots.length === 0) {
      return null;
    }

    const validationPromises = lots.map(async (lot) => {
      try {
        await lotSchema.validate(lot, { abortEarly: false });
        return null;
      } catch (errors) {
        return errors;
      }
    });

    const errorsArray = await Promise.all(validationPromises);

    const hasErrors = errorsArray.some((errors) => errors !== null);

    return hasErrors ? errorsArray : null;
  };

  return { validateLots }
}
