import { Button, DatePicker, Flex, message, TimePicker, Typography } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useFormik } from 'formik';
import React, { type ChangeEvent, type FC, useCallback, useEffect, useRef, useState } from 'react';
import { DefaultInput } from '../../../../../common/components/DefaultInput';
import { ImageUpload } from '../../../../../common/components/ImageUpload';
import { SidedLayout } from '../../../../../common/layouts/SidedLayot';
import { useAuctionsStore } from '../../../store/useAuctionsStore';
import { useGeneralInfoForm } from '../../hooks/useGeneralInfoForm';

import styles from './styles.module.scss';

dayjs.extend(customParseFormat);

interface GeneralInfoComponentProps {
  onClick?: () => void
}

const GeneralInfoComponent: FC<GeneralInfoComponentProps> = ({ onClick }) => {
  const fileRef = useRef<HTMLInputElement>(null);
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [imagePreview, setIMagePreview] = useState('');
  const auctionGeneralInfo = useAuctionsStore((state) => state.auctionGeneralInfo);
  const { initialValues, validationSchema, handleSubmit } = useGeneralInfoForm();

  const dateFormat = 'DD.MM.YYYY';
  const date = new Date();
  // TODO: Return back
  // date.setDate(date.getDate() + 1);
  date.setDate(date.getDate());
  const formattedDate = date.toLocaleDateString()

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit(onClick),
    validationSchema,
    validateOnChange: false,
    validateOnBlur: true,
  });

  const handleSelect = useCallback(
    (fieldName: string) => async (_date: dayjs.Dayjs, dateString: string | string[]) => {
      // TODO: Return back
      // const formatter = new Intl.DateTimeFormat('en-US', { day: '2-digit', year: '2-digit', month: '2-digit' });
      // if (formik.values.date && fieldName === 'time') {
      //   const FORMIK_VALUE = formik.values.date;
      //   const TOMORROW_DATE = formatter.format(date);
      //   const FORMIK_DATE = formatter.format(new Date(FORMIK_VALUE));

      //   if (TOMORROW_DATE !== FORMIK_DATE) {
      //     return await formik.setFieldValue(fieldName, dateString);
      //   }
      //   const DATE_SPLITTED = (dateString as string).split(':');
      //   const FORMIK_HOURS = Number(DATE_SPLITTED[0])
      //   // const FORMIK_MINS = Number(DATE_SPLITTED[1])

      //   if (date.getHours() > FORMIK_HOURS) {
      //     formik.setFieldError('time', 'Аукцион должен быть не менее чем за 24 часа до его начала');
      //     return;
      //   } else {
      //     formik.setFieldError('time', undefined);
      //   }
      // }

      return await formik.setFieldValue(fieldName, dateString);
    },
    [formik.values.date],
  );

  const handleChangeInput = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      formik.handleChange(e);
    },
    [formik],
  );

  const handleImageChange = useCallback(async () => {
    const uploadFile = fileRef.current?.files?.[0];

    if (!uploadFile) {
      return await message.error('File not exist');
    }

    const cashedUrl = URL.createObjectURL(uploadFile);
    setIsImageLoading(true);

    try {
      formik.setFieldValue('file', uploadFile).then(() => {
        setIMagePreview(cashedUrl);
      });
    } catch (e) {
      message.error('ошибка при загрузке изображения');
    } finally {
      setIsImageLoading(false);
    }
  }, [formik]);

  useEffect(() => {
    if (auctionGeneralInfo?.file) {
      const cashedUrl = URL.createObjectURL(auctionGeneralInfo.file);

      formik.setFieldValue('file', auctionGeneralInfo?.file);
      setIMagePreview(cashedUrl);
    }
  }, [auctionGeneralInfo?.file, formik]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Flex vertical gap={20}>
        <div>
          <ImageUpload
            name="file"
            ref={fileRef}
            onChange={handleImageChange}
            imagePreview={imagePreview}
            isLoading={isImageLoading}
            shouldRenderPreview={!!formik.values.file}
            className={formik.errors.file ? styles.errorBorder : ''}
          />
          <Typography className={styles.validation}>{formik.errors.file}</Typography>
        </div>
        <div>
        <DefaultInput
          placeholder="Введите название аукциона"
          label="Название аукциона"
          name="name"
          onChange={handleChangeInput}
          value={formik.values.name}
          className={(formik.errors.name ? styles.errorBorder : '')}
        />
        <Typography className={styles.validation}>{formik.errors.name}</Typography>
        </div>
        <div>
          <Typography className={styles.label}>Дата и время</Typography>
          <Flex justify="space-between" gap={8}>
            <div className={styles.datepicker}>
              <DatePicker
                name="date"
                minDate={dayjs(formattedDate, dateFormat)}
                className={(formik.errors.date ? styles.errorBorder : styles.datepicker)}
                placeholder="Выберите дату"
                onChange={handleSelect('date')}
              />
              <Typography className={styles.validation}>{formik.errors.date}</Typography>
            </div>
            <div className={styles.datepicker}>
              <TimePicker
                name="time"
                className={(formik.errors.time ? styles.errorBorder : styles.datepicker)}
                defaultOpenValue={dayjs('00:00', 'HH:mm')}
                placeholder="Выберите время"
                onChange={handleSelect('time')}
              />
              <Typography className={styles.validation}>{formik.errors.time}</Typography>
            </div>
          </Flex>
        </div>
        <DefaultInput
          disabled
          label="Шаг аукциона"
          placeholder="1 000₽, после 30 000 - шаг 3 000₽"
        />
      <p>
        до 20К - 1000р,
        <br />
        после 20К по 2000р
        <br />
        после 50К по 5000р
        <br />
        после 100К по 10000р
      </p>
        <Button htmlType="submit" className={styles.button} type="primary" disabled={!!formik.errors.date || !!formik.errors.file || !!formik.errors.name || !!formik.errors.time}>
          далее
        </Button>
      </Flex>
    </form>
  );
};

export const GeneralInfo: FC<GeneralInfoComponentProps> = ({ onClick }) => {
  return <SidedLayout leftChildren={<GeneralInfoComponent onClick={onClick} />} />;
};
