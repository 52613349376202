import { Col, Row } from 'antd';
import { type FC, type ReactNode } from 'react';

interface SidedLayoutProps {
  leftChildren: ReactNode
  rightChildren?: ReactNode
}

export const SidedLayout: FC<SidedLayoutProps> = ({ leftChildren, rightChildren }) => {
  return (
    <Row justify="space-between">
      <Col span={11}>{leftChildren}</Col>
      <Col span={12}>{rightChildren}</Col>
    </Row>
  );
};
