import { type IAdminAuction, type IAuction } from 'src/common/types';
import { callApi } from '../../../api/callApi';
import { ApiRoute } from '../../constans/apiRoutes';
import { type CreateAuctionBody, type EditAuctionBody } from './interfaces/model';
import { type CreateAuctionResponse } from './interfaces/response';

export const auctionService = {
  createAuction: async (data: CreateAuctionBody) => {
    // const date = new Date(data.datetime);
    // const UTC = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(),
    //   date.getUTCDate(), date.getUTCHours(),
    //   date.getUTCMinutes(), date.getUTCSeconds());

    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('datetime', new Date(data.datetime).toUTCString());
    formData.append('file', data.file, 'file');

    return await callApi<CreateAuctionResponse>({
      route: ApiRoute.AUCTIONS,
      method: 'POST',
      body: formData,
      additionalHeaders: {
        'Content-Type': 'multipart/form-data'
      },
    })
  },
  getAuctionByID: async (id: string, token?: string) => {
    try {
      return await callApi<IAdminAuction>({
        route: `${ApiRoute.AUCTIONS}/${id}`
      });
    } catch (error) {
      console.error('Error in getAuctionByID', error)
      return null;
    }
  },
  getUserAuctionByID: async (id: string): Promise<IAuction | null> => {
    try {
      return await callApi<IAuction>({
        route: `${ApiRoute.USER_AUCTIONS}/${id}`
      });
    } catch (error) {
      console.error('Error in getAuctionByID', error)
      return null;
    }
  },
  updateAuctionById: async (data: EditAuctionBody, id: string, userToken: string) => {
    const formData = new FormData();

    formData.append('name', data.name);
    formData.append('datetime', auctionService.formatDateToUTC(data.datetime));

    if (data.file !== undefined) {
      formData.append('file', data.file, 'file');
    }

    return await callApi<CreateAuctionResponse>({
      method: 'PATCH',
      route: `${ApiRoute.AUCTIONS}/${id}`,
      body: formData,
      additionalHeaders: {
        'Content-Type': 'multipart/form-data'
      },
    });
  },
  deleteAuctionById: async (id: string, userToken: string) => {
    return await callApi({
      method: 'DELETE',
      route: `${ApiRoute.AUCTIONS}/${id}`,

    });
  },
  publishAuction: async (id: string, userToken: string) => {
    return await callApi<IAdminAuction>({
      method: 'POST',
      route: `${ApiRoute.AUCTIONS}/${id}/publish`,
      additionalHeaders: {
        'Content-Type': 'multipart/form-data'
      },
    });
  },
  getAuctions: async (isPublished: boolean, isPassed: boolean) => {
    try {
      return await callApi<IAdminAuction[]>({
        method: 'GET',
        route: `${ApiRoute.AUCTIONS}/search?isPublished=${isPublished}&isPassed=${isPassed}`,
      });
    } catch (error) {
      return [];
    }
  },

  getPublishedAuctions: async (): Promise<IAuction[] | undefined> => {
    try {
      const res = await callApi<IAuction[]>({
        method: 'GET',
        route: `${ApiRoute.USER_AUCTIONS}/published`,
        excludeContentType: true
      });
      return res;
    } catch (error) {
      return [];
    }
  },

  formatDateToUTC: (dateString: string) => {
    // Create a Date object from the input string
    const date = new Date(dateString);

    // Get local offset in minutes
    const offset = date.getTimezoneOffset();

    // Calculate offset in milliseconds
    const offsetMillis = offset * 60 * 1000;

    // Calculate UTC time in milliseconds
    const utcMillis = date.getTime() + offsetMillis;

    // Create a new Date object for UTC time
    const utcDate = new Date(utcMillis);

    // Extract date components for UTC date
    const year = utcDate.getUTCFullYear();
    const month = String(utcDate.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(utcDate.getUTCDate()).padStart(2, '0');
    const hours = String(utcDate.getUTCHours()).padStart(2, '0');
    const minutes = String(utcDate.getUTCMinutes()).padStart(2, '0');
    const seconds = String(utcDate.getUTCSeconds()).padStart(2, '0');

    // Format the components into an ISO 8601 UTC string
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
  },

};
