import { useCallback } from 'react';
import * as yup from 'yup';

import { useAuctionsStore } from '../../store/useAuctionsStore';
import { type GeneralInfoStep } from '../types/AuctionInfo';

export const useGeneralInfoForm = () => {
  const { setAuctionGeneralInfo, auctionGeneralInfo } = useAuctionsStore((state) => state);

  const initialValues = {
    name: auctionGeneralInfo?.name ?? '',
    date: '',
    time: '',
    file: undefined,
  };

  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .required('Поле не может быть пустым')
      .max(64, 'Поле должно содержать не больше 64 символов'),
    date: yup.string().required('Поле не может быть пустым'),
    time: yup.string().required('Поле не может быть пустым'),
    // file: yup.string().required(),
  });

  const handleSubmit = useCallback(
    (handleClick?: () => void) => (formValues: GeneralInfoStep) => {
      const { name, time, file, date } = formValues;

      if (file) { setAuctionGeneralInfo({ name, datetime: `${date} ${time}`, file }); }

      if (handleClick) {
        handleClick();
      }
    },
    [setAuctionGeneralInfo],
  );

  return { initialValues, validationSchema, handleSubmit };
};
