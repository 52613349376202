import styles from './styles.module.scss'
import { Flex, Typography } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'src/routes/routeNames';
import Wrapper from 'src/common/components/Wrapper';

export default function About () {
  const navigate = useNavigate()

  const handleGoBack = useCallback(() => {
    navigate(ROUTES.HOME.URL)
  }, [])
  return (
    <Wrapper title={'Об аукционе'}>
            <Flex className={styles.container} justify='flex-start'>
        <Flex className={styles.backContainer} onClick={handleGoBack} justify='center' align='center'>
          <LeftOutlined />
          <Typography className={styles.back}>Назад</Typography>
        </Flex>
      </Flex>
      <p>Выводим на рынок новых художников из разных уголков мира и создаём безопасную среду для совершения сделок по покупке искусства новыми и опытными коллекционерами, даем возможность галереям и художникам реализовывать своё искусство, через нашу площадку.
      <br/>
      <br/>
Формируем высокий уровнь культуры, который в полном многообразии показывает современное искусство.
<br/>
<br/>
Мы та площадка, где появляются новые имена в современном искусстве и где простых ценителей мы превращаем в коллекционеров.
<br/>
<br/>
Для принятия участия в торгах участники аукциона до его начала проходят регистрационную форму в боте поделившись своим телефоном.
<br/>
<br/>
Механизм аукциона прост: побеждает наивысшая ставка сделанная в момент проведения торгов.
<br/>
<br/>
Шаг ставки, установленный на аукционе:
до 20000₽ шаг ставки 1000₽,
после 20000₽ шаг ставки 2000₽
после 50000₽ шаг ставки 5000₽
после 100000₽ шаг ставки 10000₽.
<br/>
<br/>
Торги проводятся в назначенную дату и время в виде автоаукциона, с возобновляемым таймером на ставку 15 сек.
До проведения аукциона вы можете сделать заочную ставку.
<br/>
<br/>
Торги проходят в рублях.
<br/>
<br/>
Участник аукциона, выигравший торги, расплачивается с организатором аукциона платежным переводом в течение 3 (трех) дней с даты проведения аукциона.
<br/>
<br/>
Купленные на аукционе произведения искусства вывозятся участником аукциона, выигравшим торги, при условии их полной оплаты, в течение 10 (десяти) календарных дней с даты проведения аукциона.
<br/>
<br/>
Доставка транспортной компанией осуществляется за счет и на риск участника.
<br/>
<br/>
Организаторы аукциона оставляют за собой право запросить у участника торгов финансовые гарантии или заблокировать аккаунт на свое усмотрение.</p>
    </Wrapper>
  )
}
