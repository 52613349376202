import { Link } from 'react-router-dom';

import { ReactComponent as LogoIcon } from '../../../assets/icons/logo.svg';
import { ROUTES } from '../../../routes/routeNames';

export const Logo = () => (
  <Link to={ROUTES.ADMIN.URL}>
    <LogoIcon />
  </Link>
);
