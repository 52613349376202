import { DatePicker, Flex, TimePicker, Typography } from 'antd';
import React, { useRef } from 'react';

import { DefaultInput } from '../../../../common/components/DefaultInput';
import { ImageUpload } from '../../../../common/components/ImageUpload';
import { useAuctionsStore } from '../../store/useAuctionsStore';
import { useEditGeneralInfo } from '../hooks/useEditGeneralInfo';

import styles from './styles.module.scss';

export const EditGeneralInfo = () => {
  const { auction } = useAuctionsStore();
  const { updateField, handleSelectDateTime, initialValues, imagePreview, handleImageChange } =
    useEditGeneralInfo();
  const fileRef = useRef<HTMLInputElement>(null);

  return (
    <Flex gap={14} vertical>
      <DefaultInput
        placeholder="Введите название аукциона"
        label="Название аукциона"
        name="name"
        value={auction?.name}
        onChange={updateField('name')}
      />
      <div>
        <Typography className={styles.label}>Дата и время</Typography>
        <Flex justify="space-between" gap={8}>
          <div className={styles.datepicker}>
            <DatePicker
              defaultValue={initialValues.date}
              name="date"
              className={styles.datepicker}
              placeholder="Выберите дату"
              onChange={handleSelectDateTime('date')}
            />
            <Typography className={styles.validation}></Typography>
          </div>
          <div className={styles.datepicker}>
            <TimePicker
              defaultValue={initialValues.time}
              name="time"
              className={styles.datepicker}
              placeholder="Выберите время"
              onChange={handleSelectDateTime('time')}
            />
            <Typography className={styles.validation}></Typography>
          </div>
        </Flex>
      </div>
      <DefaultInput disabled label="Шаг аукциона" placeholder="1 000₽, после 30 000 - шаг 3 000₽" />
      <p>
        до 20К - 1000р,
        <br />
        после 20К по 2000р
        <br />
        после 50К по 5000р
        <br />
        после 100К по 10000р
      </p>
      <div>
        <Typography className={styles.cover}>Обложка</Typography>
        <ImageUpload
          onChange={handleImageChange(fileRef)}
          ref={fileRef}
          className={styles.imageLoad}
          shouldRenderPreview={!!imagePreview}
          imagePreview={imagePreview}
        />
      </div>
    </Flex>
  );
};
