import { type FC, useMemo } from 'react';
import { Routes as Switch, Route, useLocation } from 'react-router-dom';
import { AdminLayout } from '../common/layouts/AdminLayout';
import { Admin } from '../pages/Admin';
import { CreateAuction } from '../pages/Admin/CreateAuction';
import { EditAuction } from '../pages/Admin/EditAuction';
import { Login } from '../pages/Admin/Login';
import { ReadAuction } from '../pages/Admin/ReadAuction';
import { Home } from '../pages/Home';
import MyBets from 'src/pages/MyBets';
import MyLots from 'src/pages/MyLots';
import Notifications from 'src/pages/Notifications';
import Reminders from 'src/pages/Reminders';
import Messages from 'src/pages/Admin/Messages';
import Participants from 'src/pages/Admin/Participants';
import { PrivateRoute } from './PrivateRoute';
import { ROUTES } from './routeNames';
import AuctionResults from 'src/pages/Admin/AuctionResults';
import { Participate } from '../pages/Participate';
import { MainLayout } from '../common/layouts/MainLayout';
import Auction from 'src/pages/Auction';
import About from 'src/pages/About';

export const Routes: FC = () => {
  const location = useLocation();
  const isAdminPage = useMemo(() => location.pathname.includes(ROUTES.ADMIN.URL), [location]);

  return (
    <Switch>
      {isAdminPage
        ? (
        <Route element={<PrivateRoute />}>
          <Route element={<AdminLayout />}>
            <Route path={ROUTES.ADMIN.URL} element={<Admin />} />
            <Route path={ROUTES.MESSAGES.URL} element={<Messages />} />
            <Route path={ROUTES.PARTICIPANTS.URL} element={<Participants />} />
            <Route path={ROUTES.CREATE_AUCTION.URL} element={<CreateAuction />} />
            <Route path={ROUTES.EDIT_AUCTION.URL} element={<EditAuction />} />
            <Route path={ROUTES.READ_AUCTION.URL} element={<ReadAuction />} />
            <Route path={ROUTES.METRIC.URL} element={<AuctionResults />} />
          </Route>
        </Route>
          )
        : (
        <Route element={<MainLayout/>}>
          <Route path={ROUTES.HOME.URL} element={<Home />} />
          <Route path={ROUTES.LOGIN_PAGE.URL} element={<Login />} />
          <Route path={ROUTES.AUCTIONS.URL} element={<Home />} />
          <Route path={ROUTES.AUCTION.URL} element={<Auction />} />
          <Route path={ROUTES.MY_BETS.URL} element={<MyBets />} />
          <Route path={ROUTES.MY_LOTS.URL} element={<MyLots />} />
          <Route path={ROUTES.NOTIFICATIONS.URL} element={<Notifications />} />
          <Route path={ROUTES.REMINDERS.URL} element={<Reminders />} />
          <Route path={ROUTES.ABOUT.URL} element={<About />} />
          <Route path={ROUTES.PARTICIPATE.URL} element={<Participate />} />
        </Route>
          )}
    </Switch>
  );
};
